import React, { Component } from "react";
// import SettingMenu from "../Shared/SettingMenu";
import { Row, Col, Button, Input, Card, CardBody, CardSubtitle } from "reactstrap";
import { Link } from "react-router-dom";
// Custom Scrollbar
// import SimpleBar from "simplebar-react";
import { MDBDataTable } from "mdbreact";
import { Datatable } from "../../components/Datatable"
import { Loading, LoadError } from "../../components/Loading"
import apiCall from "../../helpers/apiCall"
const host = process.env.REACT_APP_API
var prefix = host+"images/"

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:null,
      loadError:0,
      loaded:0
    };
  }

  componentDidMount() {
    apiCall("api/admin/customers",this.props.token).then(async data => {
      const parsedData = data.map((item,i)=>{
        console.log(item)
        return {
          ...item,
          img: {
            id: i,
            logo: item.logo
          },
          company: item.name,
          customerId: item.customerId,
          // orders:item.orders,
          // link: item.link!=""?<Button color="primary" target="_blank" tag={Link} to={{pathname:item.link}} outline><i className="mdi mdi-google-drive"></i> Ga naar Drive</Button>:"",
          edit: item.name//<div className="text-right"><Button  tag={Link} to={"customers/"+encodeURIComponent(item.name)+"/store"} color="primary" className="ml-auto mr-2" outline><i className="dripicons-store d-inline mr-2"></i>Klantwinkel</Button><Button  tag={Link} to={"customers/"+encodeURIComponent(item.name)} color="primary" className="ml-auto" outline><i className="mdi mdi-pencil d-inline mr-2"></i>Aanpassen</Button></div>
        }
      })
      this.setState({data:parsedData, loaded:1})
    }).catch((err)=>{
      this.setState({loadError:1, loaded:1})
      console.error(err)
    })
  }

  render() {
    
    if(!this.state.loaded){
      return <Loading/>
    }
    if(this.state.loadError){
      return <LoadError text="Er is een fout opgetreden bij het laden van Klanten." />
    }
    
    const columns = [
      {
        Header: "",
        accessor: 'img',
        Cell: ({value, row, column}) => {
          return <img ref={img => this["image-"+value.id] = img} onError={()=>{this["image-"+value.id].src=prefix+"placeholder.jpeg"}} src={prefix+value.logo} width="100px"/>
        },
        sortable: false
      },{
        Header: "Bedrijf",
        accessor: 'company',
      },{
        Header: "Klantnummer",
        accessor: 'customerId',
      },{
        Header: "Bestellingen",
        accessor: 'orders',
      },{
        Header: "",
        accessor: 'link',
        Cell: ({value, row, column}) => {
          if(value == null) {
            return null
          }
          return <Button color="primary" target="_blank" tag={Link} to={{pathname:value}} outline><i className="mdi mdi-google-drive"></i> Ga naar Drive</Button>
        },
        sortable: false,
      },{
        Header: "",
        accessor: 'edit',
        Cell: ({value, row, column}) => {
          return <div className="text-right"><Button  tag={Link} to={"customers/"+encodeURIComponent(value)+"/store"} color="primary" className="ml-auto mr-2" outline><i className="dripicons-store d-inline mr-2"></i>Klantwinkel</Button><Button  tag={Link} to={"customers/"+encodeURIComponent(value)} color="primary" className="ml-auto" outline><i className="mdi mdi-pencil d-inline mr-2"></i>Aanpassen</Button></div>
        },
        sortable: false,
      },
    ]

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={12}>
              <div className="page-title-box">
                <h4 className="font-size-18">Klanten</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/admin">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Klanten</li>
                </ol>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <div class="">
                    <Button tag={Link} color="primary" to={"/admin/customers/new"}>Nieuwe klant</Button>
                  </div>
                  {/* <MDBDataTable searchLabel={"Zoeken"} entries={100} displayEntries={false} responsive hover data={data}/> */}
                  <Datatable columns={columns} data={this.state.data}/>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/*}<Row>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-left mini-stat-img mr-4">
                      <img src={servicesIcon1} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Orders
                    </h5>
                    <h4 className="font-weight-medium font-size-24">
                      1,685{" "}
                      <i className="mdi mdi-arrow-up text-success ml-2"></i>
                    </h4>
                    <div className="mini-stat-label bg-success">
                      <p className="mb-0">+ 12%</p>
                    </div>
                  </div>
                  <div className="pt-2">
                    <div className="float-right">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1">Since last month</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-left mini-stat-img mr-4">
                      <img src={servicesIcon2} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Revenue
                    </h5>
                    <h4 className="font-weight-medium font-size-24">
                      52,368{" "}
                      <i className="mdi mdi-arrow-down text-danger ml-2"></i>
                    </h4>
                    <div className="mini-stat-label bg-danger">
                      <p className="mb-0">- 28%</p>
                    </div>
                  </div>
                  <div className="pt-2">
                    <div className="float-right">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>

                    <p className="text-white-50 mb-0 mt-1">Since last month</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-left mini-stat-img mr-4">
                      <img src={servicesIcon3} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Average Price
                    </h5>
                    <h4 className="font-weight-medium font-size-24">
                      15.8{" "}
                      <i className="mdi mdi-arrow-up text-success ml-2"></i>
                    </h4>
                    <div className="mini-stat-label bg-info">
                      <p className="mb-0"> 00%</p>
                    </div>
                  </div>
                  <div className="pt-2">
                    <div className="float-right">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>

                    <p className="text-white-50 mb-0 mt-1">Since last month</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-left mini-stat-img mr-4">
                      <img src={servicesIcon4} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Product Sold
                    </h5>
                    <h4 className="font-weight-medium font-size-24">
                      2436{" "}
                      <i className="mdi mdi-arrow-up text-success ml-2"></i>
                    </h4>
                    <div className="mini-stat-label bg-warning">
                      <p className="mb-0">+ 84%</p>
                    </div>
                  </div>
                  <div className="pt-2">
                    <div className="float-right">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1">Since last month</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>*/}
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
