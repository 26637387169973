import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Loading, LoadError, Loader } from "../../components/Loading"

import apiCall from "../../helpers/apiCall"

import { toast } from 'react-toastify';
import Select from 'react-select'
import { Row, Col, Button, Label, Input, Card, CardBody,CardText,TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink, } from "reactstrap";

import { AvForm, AvField, AvInput, AvCheckboxGroup, AvCheckbox} from "availity-reactstrap-validation";

const host = process.env.REACT_APP_API
var prefix = host+"images/"

function badges(status, paymentType) {
  var prefix = "Direct"
  if(paymentType == "account"){
    prefix = "Op rekening: "
  }
  if(status==="open"){
    return <span className="badge-soft-primary badge font-size-14 p-2">
      {prefix} Openstaand
    </span>
  } else if(status==="paid") {
    return <span className="badge-soft-success badge font-size-14 p-2">
      {prefix} Betaald
    </span>
  } else if(status==0) {
    return <span className="badge-soft-danger badge font-size-14 p-2">
      Nieuw
    </span>
  } else if(status==1) {
    return <span className="badge-soft-warning badge font-size-14 p-2">
      In behandeling
    </span>
  } else if(status==2) {
    return <span className="badge-soft-success badge font-size-14 p-2">
      Verzonden
    </span>
  } else if(status===3){
    return <span className="badge-soft-dark badge font-size-14 p-2">
      Gearchiveerd
    </span>
  }
}
class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      code: 0,
      loaded:0,
      id: this.props.match.params.id,
      notFound:false,
      width:0
    };
  }

  componentDidMount() {
    fetch(host + "api/admin/settings/", {
      method: "GET",
      credentials: "include",
      // Pass authentication token as bearer token in header
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.token}`,
      },
    }).then(async response => {
      if (response.ok) {
        var data = await response.json()
        this.setState({ data: data, code:200 })
      } else {
        this.setState({code:response.status})
        if (response.status === 401) {
          // Edge case: when the token has expired.
          // This could happen if the refreshToken calls have failed due to network error or
          // User has had the tab open from previous day and tries to click on the Fetch button
          window.location.reload()
        } else {

        }
      }
    }).catch((err)=>{
      //handle error
    })
  }

  updateSendcloudData = (event) => {
    var data = this.state.data
    console.log(event)
    data.settings.sendcloud.shipment = {
     id: event.value,
     name: event.label
    };
    this.setState({data:data})
  }

  saveSendcloud = () => {
    const {settings, shippingMethods} = this.state.data
    if(shippingMethods.findIndex((el)=>el.label == settings.sendcloud.shipment.name) == -1) {
      toast.error("Ongeldige verzendmethode")
      return
    }
    apiCall("api/admin/settings/sendcloud",this.props.token,"post", {...settings.sendcloud}).then(async data => {
      this.setState({data:{...this.state.data,settings:data}})
      toast.success("Sendcloud geupdatet.")
    }).catch((err)=>{
      // this.setState({loadError:1, loaded:1})
      toast.error("Er is een fout opgetreden bij het updaten van Sendcloud.")
      console.error(err)
    })
  }
  formatValue = () => {
    const {settings} = this.state.data
    return {
      value: settings.sendcloud.shipment.id,
      label: settings.sendcloud.shipment.name
    }
  }

  changeShipping = (ev) => {
    const {name, value} = ev.target
    let data = this.state.data
    data.settings.shippingRules[name] = value*100
    this.setState({data:data})
  }

  saveShipping = () => {
    const {settings, shippingMethods} = this.state.data
    apiCall("api/admin/settings/shipping",this.props.token,"post", {...settings.shippingRules}).then(async data => {
      this.setState({data:{...this.state.data,settings:data}})
      toast.success("Verzending geupdatet.")
    }).catch((err)=>{
      // this.setState({loadError:1, loaded:1})
      toast.error("Er is een fout opgetreden bij het updaten van Verzending.")
      console.error(err)
    })
  }

  render() {
    // if(this.state.loaded==0){
    //   return <Loading/>
    // } else if(this.state.notFound==true){
    //   return <Loading/>
    // }
    if(this.state.code != 200){
      return <Loader status={this.state.code} name={"instellingen"}/>
    }
    // if(this.state.loadError){
    //   return <LoadError text="Er is een fout opgetreden bij het laden van klant." />
    // }
    const {settings, shippingMethods} = this.state.data
    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Instellingen</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Instellingen</li>
                </ol>
              </div>
            </Col>
          </Row>

          <div className="row">
            <div className="col-12">
            <div className="card">
                <div className="card-body">
                  <h4 className="font-size-16">Sendcloud</h4>
                  <Row>
                    <Col sm="6" className="">
                      <form>
                        <Label for="">Verzendmethode (voor Nederland)</Label>
                        <Select className="mb-2 border-danger" invalid={true} options={shippingMethods} required value={shippingMethods.find((el)=>el.label == settings.sendcloud.shipment.name)} name="shipment"  onChange={this.updateSendcloudData}/>
                        <Button color={"success"} onClick={this.saveSendcloud}>Opslaan</Button>
                      </form>
                      {/* <Input type="text" name="firstName" value={settings.sendcloud.shipment.id}/> */}
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="card">
                <div className="card-body">
                  <h4 className="font-size-16">Verzending</h4>
                  <Row>
                    <Col sm="6" className="mb-2">
                        <Label for="">Kosten</Label>
                        <input className="form-control" type="number" name="value" value={settings.shippingRules.value/100} onChange={this.changeShipping}/>
                      {/* <Input type="text" name="firstName" value={settings.sendcloud.shipment.id}/> */}
                    </Col>
                    <Col sm="6" className="mb-2">
                      <Label for="">Gratis vanaf</Label>
                      <input className="form-control" type="number" name="max" value={settings.shippingRules.max/100} onChange={this.changeShipping}/>
                      {/* <Input type="text" name="firstName" value={settings.sendcloud.shipment.id}/> */}
                    </Col>
                    <Col sm="12">
                      <Button color={"success"} onClick={this.saveShipping}>Opslaan</Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function roundTo(value, decimals) {
    return Number(Math.round(+(value).toFixed(11) + 'e' + decimals) + 'e-' + decimals).toFixed(2);
}

export default Settings;
