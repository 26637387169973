import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Input,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,Media,Alert,
} from "reactstrap";
import SimpleBar from "simplebar-react";

// import images
import logodarkImg from "../../assets/images/logo-dark.png";
import logosmImg from "../../assets/images/logo-sm.png";
import logolightImg from "../../assets/images/logo-light.png";

// Import other Dropdown
import LanguageDropdown from "../../components/LanguageDropdown";
import NotificationDropdown from "../../components/NotificationDropdown";
import ProfileMenu from "../../components/ProfileMenu";

import apiCall from "../../helpers/apiCall";
function Avatar(props) {
  var color = ""
  switch(props.state){
    case "new":
      color="bg-danger avatar-title rounded-circle font-size-16"
      break;
    case "processing":
      color="bg-warning avatar-title rounded-circle font-size-16"
      break;
    case "shipped":
    case "pickup":
      color="bg-success avatar-title rounded-circle font-size-16"
      break;
    case "archived":
      color="bg-dark avatar-title rounded-circle font-size-16"
      break;
    default :
      color="bg-primary avatar-title rounded-circle font-size-16"
      break;
  }
  if(props.type==="customer")
    return <span className={color}>
        <i className="far fa-user"></i>
    </span>
  return <span className={color}>
    <i className="far fa-user"></i>
  </span>
}

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading:0,
      searchTerm:"",
      searchErr:0,
      searchLoaded:0,
      loading:1,
      data:{customers:[], orders:[]},
      menu:false
    };
  }

  loadSearch = () => {
    this.setState({loading:1})
    apiCall("api/admin/search",this.props.token).then(async data => {
      this.setState({ data: data,searchLoaded:Math.floor(new Date().getTime()/1000)+30,loading:0 })
    }).catch((err)=>{
      this.setState({ searchErr: err.msg || "Error loading search.",loading:0 })
      console.error(err)
    })

    // const authToken = getLoggedInUser();
    // axios({
    //   method: 'get', //you can set what request you want to be
    //   url: process.env.REACT_APP_BASE_URL+'search',
    //   headers: {
    //     'user': authToken.user,
    //     'id': authToken.id,
    //     'token': authToken.token
    //     // 'Content-Type': 'application/x-www-form-urlencoded',
    //   }
    // }).then(res => {
    //   if(res.status === 200){
    //     if(res.data.error === 1){
    //       this.setState({ searchErr: res.data.msg || "Error loading search.",loading:0 })
    //       return
    //     } else {
    //       this.setState({ data: res.data,searchLoaded:Math.floor(new Date().getTime()/1000)+30,loading:0 })
    //     }
    //   } else {
    //     throw res
    //   }
    // }).catch(err=>{
    //   this.setState({searchErr:"Error loading search."})
    //   if(err.response !== undefined && err.response.status === 401){
    //     this.props.logoutUser(this.props.history,window.location.pathname);
    //   }
    // })
  }

  search = (val) => {
    // var input = $("#search")
    // var value = input.val().toLowerCase().replace(/\s+/g, '');
    // $(".grid .item").filter(function() {
    //   var containsText = $(this).find(".itemtitle").text().replace(/\s+/g, '').toLowerCase().indexOf(value) > -1 || $(this).attr("data-tag").toLowerCase().replace(/\s+/g, '').indexOf(value) > -1
    //   $(this).toggle(containsText)
    // });
    if(this.state.searchLoaded <= Math.floor(new Date().getTime()/1000))
      this.loadSearch()

    var value = val.target.value.trim().replace(/\s+/g, '');
    if(value.length > 0){
      this.setState({
        menu: true,
        searchTerm: value
      });
    } else {
      this.setState({
        menu: false,
        searchTerm: ""
      });
    }
  }
  onFocus = () => {
    if(this.state.searchTerm.length>2)
      this.setState({menu:true})
  }
  filterCustomers = (el) => {
    var search = this.state.searchTerm.toLowerCase()
    var customerInvoice = this.state.data.orders.filter((el2)=>{
      console.log(el2.customer.name == el.name, el2.invoiceNo.replace(/\s+/g, '').toLowerCase().indexOf(search))
      return el2.customer.name == el.name && el2.invoiceNo.replace(/\s+/g, '').toLowerCase().indexOf(search) > -1
    }).length
    return el.name.replace(/\s+/g, '').toLowerCase().indexOf(search) > -1 || el.customerNumber.replace(/\s+/g, '').toLowerCase().indexOf(search) > -1 || customerInvoice
  }
  filterOrders = (el) => {
    var search = this.state.searchTerm.toLowerCase()

    return el.invoiceNo.replace(/\s+/g, '').toLowerCase().indexOf(search) > -1 || el.customer.name.replace(/\s+/g, '').toLowerCase().indexOf(search) > -1 || el.customer.customerNumber.replace(/\s+/g, '').toLowerCase().indexOf(search) > -1
  }

  render() {
    return (
      <React.Fragment>
      <Dropdown isOpen={this.state.menu} toggle={()=>{}} className="d-inline-block">
        <DropdownToggle tag="div" className="p-0 btn noti-icon waves-effect" id="page-header-notifications-dropdown">
        <Form className="app-search d-none d-lg-block px-2">
            <div className="position-relative">
                <Input type="text" className="form-control" style={{width:"200px"}} onFocus={this.onFocus} onBlur={()=>{setTimeout(()=>{this.setState({menu:false})},200)}} onChange={this.search} placeholder="Zoeken"/>
                <span className="fa fa-search"></span>
            </div>
        </Form>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-md p-0" onClick={()=>{this.setState({menu:true})}}
            aria-labelledby="page-header-notifications-dropdown">
            <SimpleBar className="" style={{maxHeight: "230px", minWidth:"200px"}}>
              <div className="px-3 pt-3 pb-2">
                  <Row className="align-items-center">
                      <Col>
                          <h6 className="m-0" >Klanten</h6>
                      </Col>
                  </Row>
              </div>
              {this.state.loading === 0?this.state.data.customers.filter((el)=>{return this.filterCustomers(el)}).map((el,key)=>
              <div className="text-reset notification-item pr-1">

                <Row className="align-items-center m-0">
                  <Col xs={"8"} className="py-2 pl-3">
                    <h6 className="mt-0 mb-1 " style={{wordBreak:"break-all"}}>{el.name}</h6>
                  </Col>
                  <Col xs={"2"} className="px-0">
                    <Link key={key} className="px-2 py-3 d-flex media" to={"/admin/customers/"+encodeURIComponent(el.name)+"/store"} >
                        <i className="dripicons-store  mx-auto align-self-center"></i>
                    </Link>
                  </Col>
                  <Col xs={"2"} className="px-0">

                    <Link key={key} className="px-2 py-3 d-flex media" to={"/admin/customers/"+encodeURIComponent(el.name)} >
                        <i className=" mx-auto mdi mdi-pencil align-self-center"></i>
                    </Link>
                  </Col>
                 
                </Row>

              </div>
              ):""}
              {this.state.data.customers.filter((el)=>{return this.filterCustomers(el)}).length===0 && this.state.loading === 0?
              <div className="px-3 pb-3">
                  <Row className="align-items-center text-center">
                      <Col>
                          <span className="m-0 font-size-12 text-muted">Geen resultaten</span>
                      </Col>
                      {/*}<div className="col-auto">
                          <Link to="#" className="small"> {this.props.t('View All')}</Link>
                      </div>*/}
                  </Row>
              </div>
              :""}
              <div className="px-3 pt-3 pb-2">
                  <Row className="align-items-center">
                      <Col>
                          <h6 className="m-0">Bestellingen</h6>
                      </Col>
                  </Row>
              </div>
              {this.state.loading === 0?this.state.data.orders.filter((el)=>{return this.filterOrders(el)}).map((el,key)=>
              <div className="text-reset notification-item pr-1">

                <Row className="align-items-center m-0">
                  <Col xs={"10"} className="py-2 pl-3">
                    <h6 className="mt-0 mb-1 " style={{wordBreak:"break-all"}}>{el.invoiceNo}</h6>
                  </Col>

                  <Col xs={"2"} className="px-0">
                    <Link key={key} className="px-2 py-3 d-flex media" to={"/admin/orders/"+encodeURIComponent(el.invoiceNo)} >
                        <i className="fas fa-shopping-cart mx-auto align-self-center"></i>
                    </Link>
                  </Col>
                </Row>

              </div>
              ):""}
              {this.state.data.orders.filter((el)=>{return this.filterOrders(el)}).length===0 && this.state.loading === 0?
              <div className="px-3 pb-3">
                  <Row className="align-items-center text-center">
                      <Col>
                          <span className="m-0 font-size-12 text-muted">Geen resultaten</span>
                      </Col>
                      {/*}<div className="col-auto">
                          <Link to="#" className="small"> {this.props.t('View All')}</Link>
                      </div>*/}
                  </Row>
              </div>
              :""}
              {this.state.loading === 1?<div className="px-3 pb-3">
                  <Row className="align-items-center text-center">
                      <Col>
                          <i className="mdi mdi-spin mdi-loading"></i><span className="m-0 ml-1 font-size-12 text-muted">Loading</span>
                      </Col>
                  </Row>
              </div>:""}
              <div className="m-2">
              {this.state.searchErr!==0?<Alert color="danger">
                {this.state.searchErr}
              </Alert>:""}
              </div>
            </SimpleBar>
        </DropdownMenu>
    </Dropdown>
      </React.Fragment>
    );
  }
}

export default TopBar;
