import React, { Component } from "react";
import { Row, Col, Card, CardBody, Alert } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link, Redirect } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

import Loader from "../../components/Loader";
// actions
import { loginUser } from "../../store/actions";

// import images
import logoSm from "../../assets/images/logo-dark.png";
import logosmImg from "../../assets/images/logo-sm.png";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    this.props.loginUser(values, this.props.history);
  }

  render() {
    if(this.props.token != undefined) {
      return <Redirect to="/"/>
    }
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-5">
          <div className="container">
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="position-relative">


                  <Card className="overflow-hidden">
                    <div className="">
                      <div className="text-primary text-center p-2 pt-4">

                        <Link to="/" className="logo">
                          <img src={logoSm} height="96" alt="logo" />
                        </Link>
                      </div>
                    </div>

                    <CardBody className="p-4">
                      <div className="p-3">

                        <AvForm
                          className="form-horizontal mt-4"
                          onValidSubmit={this.handleValidSubmit}
                        >
                          {this.props.error ? (
                            <Alert color="danger">{this.props.error}</Alert>
                          ) : null}

                          <div className="form-group">
                            <AvField
                              name="email"
                              label="Email"
                              className="form-control"
                              value=""
                              placeholder="Email"
                              type="email"
                              validate={{required:{valid:true, errorMessage:"Dit veld is verplicht."}}}
                            />
                          </div>
                          <div className="form-group">
                            <AvField
                              name="password"
                              label="Wachtwoord"
                              type="password"
                              validate={{required:{valid:true, errorMessage:"Dit veld is verplicht."}}}
                              value=""
                              placeholder="Wachtwoord"
                            />
                          </div>

                          <Row className="form-group">
                            <Col sm={12} className="text-center">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                              >
                                Log In
                              </button>
                            </Col>
                            <Col sm={12} className="text-center">
                              {this.props.loading ?
                              <div className="spinner-grow text-center text-primary m-1" role="status">
                                  <span className="sr-only">Loading...</span>
                              </div>: null}
                            </Col>
                          </Row>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="mt-5 text-center">
                  <p className="mb-0">
                    © {new Date().getFullYear()} LOGOnow.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  const { error, loading } = state.Login;
  return { error, loading };
};

export default withRouter(connect(mapStatetoProps, { loginUser })(Login));
