import React, { Component } from "react";
import { Row, Col, Button, UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";

import { Loading } from "../../components/Loading"

function badges(status) {
  if(status === "open"){
    return <span className="badge-soft-primary badge font-size-14 p-2">
      Betaling: Openstaand
    </span>
  } else if(status === "paid") {
    return <span className="badge-soft-success badge font-size-14 p-2">
      Betaling: Betaald
    </span>
  } else if(status === 0) {
    return <span className="badge-soft-danger badge font-size-14 p-2">
      Bestelling: Nieuw
    </span>
  } else if(status === 1) {
    return <span className="badge-soft-warning badge font-size-14 p-2">
      Bestelling: In behandeling
    </span>
  } else if(status === 2) {
    return <span className="badge-soft-success badge font-size-14 p-2">
      Bestelling: Verzonden
    </span>
  } else if(status === 3){
    return <span className="badge-soft-success badge font-size-14 p-2">
      Bestelling: Verzonden
    </span>
  }
}

function status(status){
  if(status === "open"){
    return <span className="badge-soft-warning badge font-size-14 p-2">
      openstaand
    </span>
  } else if(status === "failed"){
    return <span className="badge-soft-danger badge font-size-14 p-2">
      mislukt
    </span>
  } else if(status === "canceled"){
    return <span className="badge-soft-danger badge font-size-14 p-2">
      geannuleerd
    </span>
  } else if(status === "expired"){
    return <span className="badge-soft-warning badge font-size-14 p-2">
      verlopen
    </span>
  } else if(status === "pending"){
    return <span className="badge-soft-warning badge font-size-14 p-2">
      in behandeling
    </span>
  } else if(status === "paid"){
    return <span className="badge-soft-success badge font-size-14 p-2">
      voltooid
    </span>
  }
}
const host = process.env.REACT_APP_API
class ClientOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loaded:0,
      id: this.props.match.params.id,
      notFound:false,
      pageNumber:1,
      numPages:null,
      width:"0%"
    };
  }

  componentDidMount() {
    fetch(host + "api/details/"+this.state.id, {
      method: "GET",
      credentials: "include",
      // Pass authentication token as bearer token in header
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.token}`,
      },
    }).then(async response => {
      if (response.ok) {
        const data = await response.json()
        if(data.invoiceNo  !==  null){
          if(this.props.match.url.startsWith("/admin")){
            this.props.history.push("/admin/orders/"+data.invoiceNo)
          } else {
            this.props.history.push("/orders/"+data.invoiceNo)
          }
        }
        this.setState({ data: data, loaded:1 })
      } else {
        if (response.status  ===  401) {
          // Edge case: when the token has expired.
          // This could happen if the refreshToken calls have failed due to network error or
          // User has had the tab open from previous day and tries to click on the Fetch button
          window.location.reload()
        } else {

        }
      }
    }).catch((err)=>{
      //handle error
    })
  }

  choice = (type) => {
    fetch(host + "api/details/"+this.state.id+"/"+type, {
      method: "GET",
      credentials: "include",
      // Pass authentication token as bearer token in header
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.token}`,
      },
    }).then(async response => {
      if (response.ok) {
        const data = await response.json()
        if(data.redirect){
          if(data.redirect.startsWith("http")){
            window.location = data.redirect
          } else {
            this.props.history.push(data.redirect)
          }
        }
      } else {
        if (response.status  ===  401) {
          // Edge case: when the token has expired.
          // This could happen if the refreshToken calls have failed due to network error or
          // User has had the tab open from previous day and tries to click on the Fetch button
          window.location.reload()
        } else {

        }
      }
    }).catch((err)=>{
      //handle error
    })
  }

  render() {
    if(this.state.loaded === 0){
      return <Loading/>
    } else if(this.state.notFound === true){
      return <Loading/>
    }
    const paymentStatus = this.state.data.status
    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Bestelling details</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Details</li>
                </ol>
              </div>
            </Col>
          </Row>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <Row>
                    <Col md="12">
                      <h4>Je Betaling is {status(paymentStatus)}</h4>
                      <React.Fragment>
                      <Button className="mr-2" color="primary" onClick={()=>{this.choice("retry")}}>{paymentStatus=="open"?"Betaling afronden":"Betaling opnieuw proberen"}</Button>
                      <Button className="mr-2" color="danger" onClick={()=>{this.choice("cancel")}}>Bestelling Annuleren</Button>
                      {this.state.data.account.allowed || this.props.match.url.startsWith("/admin")?
                      <>
                      <span id=""></span>
                      <div id="convert" className="d-inline-block" >
                        <Button color="success" style={{pointerEvents: this.props.match.url.startsWith("/admin")?"":"none"}} onClick={()=>{this.choice("convert")}} disabled={this.state.data.account.limit && !this.props.match.url.startsWith("/admin")}>Bestelling op rekening zetten 
                        </Button>
                      </div>
                      {this.state.data.account.limit && !this.props.match.url.startsWith("/admin")?<UncontrolledTooltip placement="right" target="convert">
                        Het bestel bedrag is te hoog om op factuur te kunnen zetten
                      </UncontrolledTooltip>:null}
                      </>
                      :null}
                      
                      </React.Fragment>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function roundTo(value, decimals) {
    return Number(Math.round(+(value).toFixed(11) + 'e' + decimals) + 'e-' + decimals).toFixed(2);
}

export default ClientOrders;
