import React, { Component } from "react";
import { Row, Col, Alert } from "reactstrap";

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }
  render() {
    return (
      <div style={{height: "100%",
        width: "100%",
        position: "fixed",
        left: 0,
        background: "white",
        top: 0}}
      >
        <div style={{position:"absolute",left:"50%",top:"50%", transform:"translate(-50%, -50%)"}}>
          <i className="mdi-spin mdi mdi-loading fa-4x"></i>
        </div>
      </div>
    )
  }
}

class LoadError extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }
  render() {
    return (
      <div className="container-fluid">
        <Row className="align-items-center">
          <Col sm={12}>
            <Alert color="danger" className="my-3">
              {this.props.text}
            </Alert>
          </Col>
        </Row>
      </div>
    )
  }
}


class Loader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  render() {
    const status = this.props.status
    if(status === 0 || status === undefined){
      return <Loading/>
    } else if(status === 404) {
      //Not found
      return "404"
    } else {
      return (
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={12}>
              <Alert color="danger" className="my-3">
                Er is een onverwachte fout opgetreden bij het laden van {this.props.name}
              </Alert>
            </Col>
          </Row>
        </div>
      )
    }
  }
}

export { LoadError }
export { Loading }
export { Loader }
