import React, { Component, createRef } from 'react';
import ReactDOM from 'react-dom';
import {Card, CardBody,CardText, Row, Col, Input, Label, FormGroup, Button, Modal, InputGroup, InputGroupAddon, InputGroupText, UncontrolledDropdown,
DropdownMenu,
DropdownItem,
DropdownToggle,
UncontrolledTooltip} from 'reactstrap'
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import Image from "./Image";

import { toast } from 'react-toastify';

import apiCall from "../../helpers/apiCall"
import zxcvbn from "zxcvbn";
const { v4: uuidv4 } = require('uuid');
const getStrengthColor = strength => {
  switch (strength) {
    case 0:
      return "danger";
    case 1:
      return "danger";
    case 2:
      return "warning";
    case 3:
      return "warning";
    case 4:
      return "success";
    default:
      return "primary";
  }
};
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing: 0,
      user: null,
      email:"",
      firstname:"",
      lastname:"",
      setPassword:null,
      strengthcolor:getStrengthColor(-1)
    };
    this.form = createRef()
  }


  componentDidMount() {
    this.setState({user: this.props.user})
  }

  save = (e,data) => {
    //data = {...data,setPassword:this.state.setPassword}
    if(this.state.user===null) {
      data = {...data,setPassword:this.state.setPassword}
    }
    apiCall("api/admin/customers/"+encodeURIComponent(this.props.id)+"/user",this.props.token,"post", data ).then(async data => {
      if(this.state.user===null) {
        this.setState({user:data})
      }
      toast.success("Gebruiker opgeslagen.")
    }).catch(async (err)=>{
      if(err.json != undefined){
        const data = await err.json()

        if(data.error){
          toast.error(data.error)
          return
        }
      }
      // this.setState({loadError:1, loaded:1})
      toast.error("Er is een fout opgetreden bij het opslaan van gebruiker.")
      console.error(err)
    })
  }

  sendReset = () => {
    this.setState({setPassword:false})
    this.form.current.submit()
  }

  sendPassword = () => {
    this.setState({setPassword:true})
    this.form.current.submit()
  }

  resetPassword = () => {
    apiCall("api/admin/customers/"+encodeURIComponent(this.props.id)+"/user/resetpasswd",this.props.token,"post", {} ).then(async data => {
      toast.success("Reset link toegestuurd.")
    }).catch((err)=>{
      // this.setState({loadError:1, loaded:1})
      toast.error("Er is een fout opgetreden bij het resetten van het wachtwoord.")
      console.error(err)
    })
  }

  setPassword = (e,data) => {
    if(data.password == ""){
      toast.error("Het wachtwoord veld mag niet leeg zijn.")
      return
    }
    apiCall("api/admin/customers/"+encodeURIComponent(this.props.id)+"/user/resetpasswd",this.props.token,"post", {setPassword:true, data:data} ).then(async data => {
      toast.success("Wachtwoord ingesteld.")
    }).catch(async (err)=>{
      if(err.json != undefined){
        const data = await err.json()
        if(data.error){
          toast.error(data.error)
          return
        }
      }
      // this.setState({loadError:1, loaded:1})
      toast.error("Er is een fout opgetreden bij het instellen van het wachtwoord.")
      console.error(err)
    })
    return
  }

  passwordChange = (ev) => {
    var password = ev.target.value
    if(password == ""){
      this.setState({
        strengthcolor:getStrengthColor(-1)
      })
      return
    }
    const { score } = zxcvbn(password);
    this.setState({
      strengthcolor:getStrengthColor(score)
    })
  }

  validate = (password) =>{
    if(password == ""){
      return true
    }
    const { score } = zxcvbn(password);
    if(score < 4)
      return "Wachtwoord niet sterk genoeg"
    return true
  }

  render() {
    if(this.state.user===null) {
      return <React.Fragment>
              <h3>Gebruiker</h3>
              <AvForm ref={this.form} onValidSubmit={this.save}>
                <Row>
                  <Col>
                    <Label for="firstname">Voornaam</Label>
                    <AvField validate={{required:{valid:true, errorMessage:"Dit veld is verplicht."}}} type="text" name="firstname"/>
                  </Col>
                  <Col>
                    <Label for="lastname">Achternaam</Label>
                    <AvField validate={{required:{valid:true, errorMessage:"Dit veld is verplicht."}}} type="text" name="lastname"/>
                  </Col>
                  <Col>
                    <Label for="email">Email</Label>
                    <AvField validate={{required:{valid:true, errorMessage:"Dit veld is verplicht."}}} type="email" name="email"/>
                  </Col>
                  <Col xs={12}>
                    <Label for="password">Wachtwoord</Label>
                    <AvField style={{borderBottom:"3px solid var(--"+this.state.strengthcolor+")"}}  validate={{required:{valid:true, errorMessage:"Dit veld is verplicht."},myValidation: this.validate}} required={this.state.setPassword} onChange={this.passwordChange} className="mb-0" type="password" name="password" />
                  </Col>
                  <Col xs={12} className="d-flex align-items-center">
                    <Button type="button" onClick={this.sendPassword} color="success">Maak gebruiker met wachtwoord</Button>
                    <p className="my-1 mx-2">- of -</p>
                    <Button  type="button" onClick={this.sendReset}  color="success">Maak gebruiker en stuur wachtwoord instel link</Button>
                  </Col>
                </Row>
              </AvForm>
      </React.Fragment>
    }
    //
    // const defaultValues = {
    //   firstname: this.state.user.firstname,
    //   lastname: this.state.user.lastname,
    //   name: 'Availity',
    //   email: this.state.user.email,
    // };

    return (
      <React.Fragment>
              <h3>Gebruiker</h3>
              <AvForm ref={this.form} onValidSubmit={this.save}>
                <Row>
                  <Col>
                    <Label for="firstname">Voornaam</Label>
                    <AvField validate={{required:{valid:true, errorMessage:"Dit veld is verplicht."}}} type="text" name="firstname" value={this.state.user.firstname}/>
                  </Col>
                  <Col>
                    <Label for="lastname">Achternaam</Label>
                    <AvField validate={{required:{valid:true, errorMessage:"Dit veld is verplicht."}}} type="text" name="lastname" value={this.state.user.lastname}/>
                  </Col>
                  <Col>
                    <Label for="email">Email</Label>
                    <AvField validate={{required:{valid:true, errorMessage:"Dit veld is verplicht."}}} type="email" name="email" value={this.state.user.email}/>
                  </Col>
                  <Col xs={12} className="d-flex align-items-center mb-3">
                    <Button type="submit" color="success">Sla gegevens op</Button>
                  </Col>
                </Row>
              </AvForm>
              <hr/>
              <AvForm onValidSubmit={this.setPassword}>
                <Row>
                  <Col xs={12}>
                    <Label for="password">Wachtwoord</Label>
                    <AvField style={{borderBottom:"3px solid var(--"+this.state.strengthcolor+")"}} validate={{myValidation: this.validate}} onChange={this.passwordChange}  className="mb-0" type="password" name="password" />
                  </Col>
                  <Col xs={12} className="d-flex align-items-center">
                    <Button type="submit" color="success">Update wachtwoord</Button>
                    <p className="my-1 mx-2">- of -</p>
                    <Button  type="button" onClick={this.resetPassword}  color="success">Stuur wachtwoord reset link</Button>
                  </Col>
                </Row>
              </AvForm>
      </React.Fragment>
    );
  }
}

export default App
