import React, { Component } from "react";
import { Row, Col, Modal, Button, Card, CardBody, InputGroup,Label } from "reactstrap";
import { Link } from "react-router-dom";
// Custom Scrollbar
import Cart from "./cart";
import { AvForm, AvField, AvInput, AvCheckboxGroup, AvCheckbox} from "availity-reactstrap-validation";

import { toast } from 'react-toastify';

import apiCall from "../../helpers/apiCall"
import { Loading, LoadError } from "../../components/Loading"

function roundTo(value, decimals) {
    return Number(Math.round(+(value).toFixed(11) + 'e' + decimals) + 'e-' + decimals).toFixed(2);
}
const host = process.env.REACT_APP_API
var prefix = host+"images/"

class Store extends Component {
  constructor(props) {
    super(props);
    this.checkoutRef = React.createRef()
    this.state = {
      checkingOut: false,
      loaded:0,
      loadError:0,
      checkout:0,
      invoiceAddress:{},
      differentDeliveryAddress:false,
      shippingMethod:"ship",
      deliveryAddress:{
        addition: "",
        city: "",
        country: "NL",
        email: "",
        firstName: "",
        houseNo: "",
        lastName: "",
        postalCode: "",
        street: ""
      },
      data:[],
      customerName: decodeURIComponent(this.props.match.params.id),
      variants:false,
      product:null,
      notes:"",
      category:null,
      shippingOverride:""
    };
    this.form = React.createRef()
  }

  updateVariants = (key,event) => {
    var data = this.state.products
    var index = this.state.variantId;
    // index = data[index].variants.findIndex((el)=>el.id === key)
    // console.log()
    if(event === "-"){
      data[index].variants[key].num--
    } else if(event === "+") {
      data[index].variants[key].num++
    } else {
      data[index].variants[key].num = parseInt(event.target.value,10)||""
    }
    //console.log(event.target.value,index,key,data[index].sizes[key])
    this.setState({data:data})
  }

  updateQty = (category, product, variant,  event) => {
    // console.log(key,event)
    var data = this.state.categories
    // var num = data[category].products[product].variants[0].num
    // key = data.findIndex((el)=>el.id === key)
    data[category].products[product].variants[variant].keep = false;
    if(event === "-"){
      data[category].products[product].variants[variant].num--
    } else if(event === "-total"){
      data[category].products[product].variants[variant].num--
      if(data[category].products[product].variants[variant].num <= 0) {
        data[category].products[product].variants[variant].keep = true;
      }
    } else if(event === "+") {
      data[category].products[product].variants[variant].num++
    } else if(event === "remove") {
      data[category].products[product].variants[variant].num = 0;
    } else if(event.target.name == "cart") {
      data[category].products[product].variants[variant].num = parseInt(event.target.value,10) || ""
      if(data[category].products[product].variants[variant].num <= 0) {
        data[category].products[product].variants[variant].keep = true;
      }
    } else {
      data[category].products[product].variants[variant].num = parseInt(event.target.value,10) || ""
    }
    this.setState({categories:data})
  }

  scrollToCheckout = (val) => {
    //this.setState({checkout:val})
    //this.checkoutRef.current.scrollIntoView()
    window.scrollTo({
      top: this.checkoutRef.current.offsetTop,
      behavior: "smooth"
    })
  }

  deliveryAddress = (ev) => {
    var name = ev.target.name
    var value = ev.target.value
    var deliveryAddress = this.state.deliveryAddress
    deliveryAddress[name] = value
    this.setState({deliveryAddress:deliveryAddress})
  }
  notes = (ev) => {
    var value = ev.target.value
    this.setState({notes:value})
  }

  deliveryAddressCheckbox = (val) => {
    this.setState({differentDeliveryAddress:val})
  }

  componentDidMount () {
    if(this.state.customerName !== "undefined"){
      apiCall("api/admin/store/"+encodeURIComponent(this.state.customerName),this.props.token).then(async data => {
        // var categories = reshape(data.products)
        // console.log(categories)
        // this.setState({categories: categories})
        const categories = data.categories.map((category)=>{
          return {
            ...category,
            products: category.products.map((product)=>{
              return {
                ...product,
                variants:product.variants.map((variant)=>{
                  return {
                    ...variant,num:"",keep:false
                  }
                })
              }
            })
          }
        })

        delete data.categories
        // data.deliveryAddress = {
        //   addition: ""
        //   city: ""
        //   country: "NL"
        //   email: ""
        //   firstName: ""
        //   houseNo: ""
        //   lastName: ""
        //   postalCode: ""
        //   street: ""
        // }
        data.invoiceAddress = data.address
        delete data.address
        this.setState({
          ...data,
          categories: [...categories],
          loaded:1})
      }).catch((err)=>{
        this.setState({loadError:1, loaded:1})
        console.error(err)
      })
    } else {
      apiCall("api/store",this.props.token).then(async data => {
        // var categories = reshape(data.products)
        // console.log(categories)
        // this.setState({categories: categories})
        const categories = data.categories.map((category)=>{
          return {
            ...category,
            products: category.products.map((product)=>{
              return {
                ...product,
                variants:product.variants.map((variant)=>{
                  return {
                    ...variant,num:0
                  }
                })
              }
            })
          }
        })
        delete data.categories
        data.shippingAddress = data.address
        data.invoiceAddress = data.address
        delete data.address
        this.setState({
          ...data,
          categories: [...categories],
          loaded:1})
      }).catch((err)=>{
        this.setState({loadError:1, loaded:1})
        console.error(err)
      })
    }
  }

  checkout = async (paymentType) => {
    var lineItems = []
    this.state.categories.forEach((category)=>{
      category.products.forEach((product)=>{
        product.variants.forEach((variant)=>{
          if(variant.num != 0){
            lineItems.push({
              id:variant.id,
              qty:parseInt(variant.num),
            })
          }
        })
      })
    })
    var checkoutData = {
      paymentType: paymentType,
      lineItems:lineItems,
      notes:this.state.notes,
      shippingMethod: this.state.shippingMethod
    }
    if(this.state.differentDeliveryAddress){
      this.form.current.submit()
      if(this.form.current.hasError()) {
        return;
      }
      checkoutData.shippingAddress = this.state.deliveryAddress
    }

    if(this.state.customerName !== "undefined") {
      checkoutData.customer = this.state.customerName
      checkoutData.shippingPrice = this.state.shippingOverride
      console.log("ADMIN")
    }
    this.setState({checkingOut: true})
    apiCall("api/checkout",this.props.token,"post", checkoutData).then(async data => {
      if(data.redirect){
        if(data.redirect.startsWith("http")){
          window.location = data.redirect
        } else {
          this.props.history.push(data.redirect)
        }
      } else if(data.error){
        toast.error(data.error)
      }
      this.setState({checkingOut: false})
    }).catch((err)=>{
      console.error(err)
      this.setState({checkingOut: false})
    })
  }

  getShippingPrice = (total,always) => {
    console.log(this.state.shipping)
    if(this.state.customerName !== "undefined" && this.state.shippingOverride != "") {
      return parseFloat(this.state.shippingOverride)*100
    }
    if(total >= this.state.shipping.min && total < this.state.shipping.max && (this.state.shippingMethod == "ship" || always == true)){
      return this.state.shipping.value
    }
    return 0
  }

  getTotal = (total) => {
    if(total > 0) {
      return total+this.getShippingPrice(total)
    }
    return 0
  }

  render() {
    if(!this.state.loaded){
      return <Loading/>
    }
    if(this.state.loadError){
      return <LoadError text="Er is een fout opgetreden bij het laden van het Dashboard." />
    }
    // window.test = this.form.current
    // const categories = [...new Set(this.state.products.map((el)=>el.category))]
    var isEqual = 0;
    if(this.state.category !== null){
      var data = this.state.categories[this.state.category].products[this.state.product]
      isEqual = data.variants.every( v => v.price === data.variants[0].price )
    }
    var cartData = []
    this.state.categories.forEach((category,cat)=>{
      category.products.forEach((product,prod)=>{
        if(product.variants.length !== 1){
          Object.keys(product.variants).forEach((key)=>{
            if(product.variants[key].num != 0 || product.variants[key].keep == true){
              cartData.push({
                id:[cat,prod,key],
                count:product.variants[key].num,
                name:`${product.name} ${product.variants[key].name}`,
                price: product.variants[key].price
              })
            }
          })
        } else {
          if(product.variants[0].num != 0 || product.variants[0].keep == true){
            cartData.push({
              id:[cat,prod,0],
              count:product.variants[0].num,
              name:`${product.name}`,
              price: product.variants[0].price
            })
          }
          //cartData.push({count:,name:,price:})
        }
      })
    })
    var numItems = cartData.reduce((acc,val)=>{return acc+val.count},0)
    const total = cartData.reduce((accumulator,value)=>accumulator+value.count*value.price,0)
    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={9}>
              <div className="page-title-box">
                <h4 className="font-size-18">Producten</h4>
                {this.state.customerName !== "undefined"?
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/admin">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/customers">Klanten</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to={"/admin/customers/"+encodeURIComponent(this.state.customerName)}>{this.state.customerName}</Link>
                  </li>
                  <li className="breadcrumb-item active">Winkel</li>
                </ol>
                :
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Winkel</li>
                </ol>
                }
              </div>
            </Col>
            <Col sm="3" className="text-right">

              <div className={this.state.checkout===1?"d-none":""}>
                <Cart data={cartData} checkout={this.scrollToCheckout}/>
              </div>
              {/*<div className={this.state.checkout===0?"d-none":""}>
                <Button color="success" onClick={()=>{this.setState({checkout:0})}} outline>Terug naar producten</Button>
              </div>*/}
            </Col>
          </Row>
          <div className={this.state.checkout===1?"d-none":""}>
          {this.state.categories.map((category,categoryId)=>
            {
              if(category.products.length === 0 || category.products.reduce((acc,x)=>{return [...acc,...x.variants]},[]).length === 0)
                return ""
              return <React.Fragment key={categoryId}>

          <h2>{category.name}</h2>
          <Row className="align-items-center">
            {category.products.map((product,i)=>{
              if(product.variants.length === 0)
                return ""
              if(product.variants.length === 1){
                const variant = product.variants[0]
                return (<Col sm={6} md={6} lg={4} xl={3} key={i}>
                  <Card>
                    <div className="m-2 mb-0">
                      <img alt="Product"  src={prefix+product.img} className="w-100 card-img-top img-fluid rounded-top"/>
                    </div>
                    <CardBody>
                    <div className="mb-2"><h4 className="d-inline">{product.name}</h4>
                    <Label className="d-inline ml-2" for={"size"+i}>{"(€"+roundTo(variant.price/100,2)+")"}</Label></div>
                    <Row>
                      <Col sm={12} md={8}>
                        <InputGroup className="w-100">
                          <div className="input-group-prepend">
                            <Button type="button" color="primary" onClick={(ev)=>this.updateQty(categoryId,i,0,"-")} disabled={variant.num<=0?true:false}>
                              <i className="mdi mdi-minus"></i>
                            </Button>
                          </div>
                          <input
                            type="number"
                            className="form-control hide-arrows"
                            placeholder="0"
                            min="0"
                            value={variant.num}
                            name={"size"+i}
                            onChange={(ev)=>this.updateQty(categoryId,i,0,ev)}
                          />
                          <div className="input-group-append">
                            <Button type="button" color="primary" onClick={(ev)=>this.updateQty(categoryId,i,0,"+")}>
                              <i className="mdi mdi-plus"></i>
                            </Button>
                          </div>
                        </InputGroup>
                      </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>)
              } else {
                return (<Col  sm={6} md={6} lg={4} xl={3} key={i}>
                  <Card>
                    <div className="m-2 mb-0">
                      <img alt="Product" src={prefix+product.img} className="w-100 card-img-top img-fluid rounded-top"/>
                    </div>
                    <CardBody>

                      <h4>{product.name}</h4>
                      <Button color="primary" onClick={()=>{this.setState({variants:true,category:categoryId,product:i})}} outline>Kies varianten</Button>
                    </CardBody>
                  </Card>
                </Col>)
              }
            })}
          </Row>

          </React.Fragment>}
        )}
        </div>
        <div ref={this.checkoutRef} className={this.state.checkout===1?"d-none":""}>
        <Card>
          <CardBody>
          <h4>Afrekenen</h4>
          <AvForm ref={this.form}>
          <Row className="">
            <Col sm="6">

            <h5>Factuur gegevens</h5>
            <p>
              {this.state.invoiceAddress.firstName} {this.state.invoiceAddress.lastName}<br/>
              {this.state.invoiceAddress.email}<br/>
              {this.state.invoiceAddress.street} {this.state.invoiceAddress.houseNo}{this.state.invoiceAddress.addition}<br/>
              {this.state.invoiceAddress.postalCode} {this.state.invoiceAddress.city}
            </p>
            <div className="custom-control custom-checkbox mb-2">
                  <input type="checkbox" id="shippingAddress" checked={this.state.differentDeliveryAddress} onChange={(ev)=>{console.log(ev.target.checked); this.setState({differentDeliveryAddress:ev.target.checked})}} className=" shippingAddress custom-control-input"/>
                  <label className="custom-control-label" for="shippingAddress" >Afleveradres is anders dan het factuuradres</label>
								</div>
            <div className={this.state.differentDeliveryAddress?"shipping row":"shipping row d-none"} >
                <div class="col-12">
                  <h5>Aflever gegevens</h5>
                </div>
                
                <div class="col-6">
  								<div class="form-group">
  									<label for="firstName" class="">Voornaam</label>
  									<AvInput type="text" class="form-control" name="firstName" disabled={!this.state.differentDeliveryAddress} onChange={this.deliveryAddress} value={this.state.deliveryAddress.firstName} required/>
  								</div>
  							</div>
  							<div class="col-6">
  								<div class="form-group">
  									<label for="lastName">Achternaam</label>
  									<AvInput type="text" class="form-control" name="lastName" disabled={!this.state.differentDeliveryAddress} onChange={this.deliveryAddress} value={this.state.deliveryAddress.lastName} required/>
  								</div>
  							</div>
  							<div class="col-12">
  								<div class="form-group">
  									<label for="email">Email</label>
  									<AvInput type="email" class="form-control" name="email" disabled={!this.state.differentDeliveryAddress} onChange={this.deliveryAddress} value={this.state.deliveryAddress.email} required/>
  								</div>
  							</div>
  							<div class="col-6">
  								<div class="form-group">
  									<label for="street">Straat</label>
  									<AvInput type="text" class="form-control" name="street" disabled={!this.state.differentDeliveryAddress} onChange={this.deliveryAddress} value={this.state.deliveryAddress.street} required/>
  								</div>
  							</div>
  							<div class="col-3">
  								<div class="form-group">
  									<label for="houseNo">Huisnummer</label>
  									<AvInput type="text" class="form-control" name="houseNo" disabled={!this.state.differentDeliveryAddress} onChange={this.deliveryAddress} value={this.state.deliveryAddress.houseNo} required/>
  								</div>
  							</div>
  							<div class="col-3">
  								<div class="form-group">
  									<label for="addition">Toevoeging</label>
  									<AvInput type="text" class="form-control" name="addition" disabled={!this.state.differentDeliveryAddress} onChange={this.deliveryAddress} value={this.state.deliveryAddress.addition}/>
  								</div>
  							</div>
  							<div class="col-6">
  								<div class="form-group">
  									<label for="postalCode">Postcode</label>
  									<AvInput type="text" class="form-control" name="postalCode" disabled={!this.state.differentDeliveryAddress} onChange={this.deliveryAddress} value={this.state.deliveryAddress.postalCode} required/>
  								</div>
  							</div>
                <div class="col-6">
  								<div class="form-group">
  									<label for="city">Plaats</label>
  									<AvInput type="text" class="form-control" name="city" disabled={!this.state.differentDeliveryAddress} onChange={this.deliveryAddress} value={this.state.deliveryAddress.city} required/>
  								</div>
  							</div>
                <div class="col-3 d-none">
  								<div class="form-group">
  									<label for="city">Land</label>
                    <select class="form-control" disabled value="NL">
                      <option disabled value="NL">Nederland</option>
                    </select>
  								</div>
  							</div>
                

              </div>
              <div className="mb-2">
                <label for="notes">Notities</label>
                <textarea class="form-control" name="notes" onChange={this.notes} value={this.state.notes}></textarea>

              </div>
            </Col>
            <Col sm="6">
            <table id="" className="table" cellspacing="0" width="100%">
  						<thead>
  							<tr>
  								<th className="th-sm">Aantal</th>
  								<th className="th-sm">Product</th>
  								<th className="th-sm text-right">Stukprijs</th>
                  <th className="th-sm text-right">Prijs</th>
                  <th className="th-sm"></th>
  							</tr>
  						</thead>
              <tbody class="orderInfo">
                {cartData.map((line)=>
                  <tr>
                    <td style={{width:"175px"}}>
                      <InputGroup>
                        <div className="input-group-prepend">
                          <Button type="button" color="primary" disabled={line.count<=0?true:false} onClick={(ev)=>this.updateQty(line.id[0],line.id[1],line.id[2],"-total")}>
                            <i className="mdi mdi-minus"></i>
                          </Button>
                        </div>
                        <input
                          type="number"
                          className="form-control hide-arrows"
                          placeholder="0"
                          min="0"
                          name="cart"
                          value={line.count}
                          onChange={(ev)=>this.updateQty(line.id[0],line.id[1],line.id[2],ev)}
                        />
                        <div className="input-group-append">
                          <Button type="button" color="primary" onClick={(ev)=>this.updateQty(line.id[0],line.id[1],line.id[2],"+")}>
                            <i className="mdi mdi-plus"></i>
                          </Button>
                        </div>
                      </InputGroup>
                    </td>
                    <td>
                      {line.name}
                    </td>
                    <td className="text-right">
                      &euro;{roundTo(line.price/100,2)}
                    </td>
                    <td className="text-right">
                      &euro;{roundTo(line.count*line.price/100,2)}
                    </td>
                    <td className="text-center">
                      <Button type="button" color="danger" onClick={(ev)=>this.updateQty(line.id[0],line.id[1],line.id[2],"remove")}>
                        <i className="mdi mdi-trash-can-outline"></i>
                      </Button>
                    </td>
                  </tr>
                )}
              </tbody>
  						<tbody>

                <tr>
                  <td colSpan={3}>{numItems} Artikel{numItems!=1?"en":""}</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
  								<td colSpan={3}>Subtotaal (ex. BTW)</td>
  								<td className="text-right"><b>&euro;{roundTo(total/100,2)}</b></td>
                  <td></td>
  							</tr>
                {this.state.shippingMethod == "ship"?
                <tr>
                  <td colSpan={3}>Verzending</td>
                  <td className="text-right">{ this.state.customerName != "undefined"?
                    <input className="text-right form-control" value={this.state.shippingOverride} onChange={(ev)=>this.setState({shippingOverride: ev.target.value})} placeholder={roundTo(this.getShippingPrice(total)/100,2)}/>
                    :<b>&euro;{roundTo(this.getShippingPrice(total)/100,2)}</b>
                  }</td>
                  <td></td>
                </tr>
                :null}
                {/* {this.state.paymentTypes.account.allowed?<Button color="success" onClick={()=>{this.checkout("account")}} className="h-100 mr-2 " disabled={numItems === 0 || (total > this.state.paymentTypes.account.limit*100 && this.state.paymentTypes.account.limit !== 0) || this.state.checkingOut == true}>Op rekening afrekenen{this.state.paymentTypes.account.limit!== 0?<span style={{fontSize:"10px"}}><br/>(max. &euro;{this.state.paymentTypes.account.limit})</span>:""}</Button>:""} */}
                <tr>
  								<td colSpan={3}>BTW</td>
  								<td className="text-right"><b>&euro;{roundTo((this.getTotal(total))*0.21/100,2)}</b></td>
                  <td></td>
  							</tr>
                <tr>
  								<td colSpan={3}>Totaal</td>
  								<td className="text-right"><b>&euro;{roundTo((this.getTotal(total))*1.21/100,2)}</b></td>
                  <td></td>
  							</tr>
  						</tbody>
  					</table>

            </Col>
            <Col sm="12">
              <div className="mb-2">
                  <div className="custom-control custom-radio">
                    <input className="custom-control-input" type="radio" checked={this.state.shippingMethod == "ship"} onClick={()=>this.setState({shippingMethod:"ship"})} name="flexRadioDefault" id="flexRadioDefault1"/>
                    <label className="custom-control-label" for="flexRadioDefault1">
                      Verzenden (&euro;{roundTo(this.getShippingPrice(total,true)/100,2)})
                    </label>
                  </div> 
                  <div className="custom-control custom-radio">
                    <input className="custom-control-input" type="radio" checked={this.state.shippingMethod == "pickup"} onClick={()=>this.setState({shippingMethod:"pickup"})} name="flexRadioDefault" id="flexRadioDefault2"/>
                    <label className="custom-control-label" for="flexRadioDefault2">
                      Ophalen
                    </label>
                  </div> 
              </div>
            </Col>
            <Col sm="12">
                {this.state.paymentTypes.account.allowed?<Button color="success" onClick={()=>{this.checkout("account")}} className="h-100 mr-2 " disabled={numItems === 0 || (total > this.state.paymentTypes.account.limit*100 && this.state.paymentTypes.account.limit !== 0) || this.state.checkingOut == true}>Op rekening afrekenen{this.state.paymentTypes.account.limit!== 0?<span style={{fontSize:"10px"}}><br/>(max. &euro;{this.state.paymentTypes.account.limit})</span>:""}</Button>:""}
                {this.state.paymentTypes.direct.allowed?<Button color="warning" onClick={()=>{this.checkout("direct")}} className="h-100" disabled={numItems === 0 || (total > this.state.paymentTypes.direct.limit*100 && this.state.paymentTypes.direct.limit!== 0) || this.state.checkingOut == true}>Direct afrekenen{this.state.paymentTypes.direct.limit!== 0?<span style={{fontSize:"10px"}}><br/>(max. &euro;{this.state.paymentTypes.direct.limit})</span>:""}</Button>:""}
            </Col>
            </Row>
            </AvForm>
            </CardBody>
          </Card>
        </div>
          {this.state.category !== null?
          <Modal
            isOpen={this.state.variants}
            toggle={()=>{this.setState({variants:!this.state.variants})}}
          >
            <div className="modal-header">
              <Row className="w-100">
                <Col xs={"8"}>
                  <h5 className="modal-title mt-0" id="">
                    {data.name}
                  </h5>
                </Col>
                <Col xs={"4"} className="text-right">
                  {isEqual?
                    <h5 className="modal-title mt-0" id="">
                      €{roundTo(data.variants[0].price/100,2)}
                    </h5>:""
                  }
                </Col>
              </Row>
              {/*<button
                type="button"
                onClick={()=>{this.setState({variants:false})}}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>*/}
            </div>
            <div className="m-2 mb-0">
              <img alt="Product" src={prefix+data.img} className="w-100 card-img-top img-fluid"/>
            </div>
            <div className="modal-body">
              <div className="mx-5">
                {data.variants.map((variant,i)=>
                  <div key={i}>
                    <Row>
                    <Col className="align-self-center" xs="3"><Label for={"size"+i}>{variant.name+(isEqual?"":" (€"+roundTo(variant.price/100,2)+")")}</Label></Col>
                    <Col className="align-self-center" xs="9"> 
                      <InputGroup className="mb-2">
                        <div className="input-group-prepend">
                          <Button type="button" color="primary" onClick={(ev)=>this.updateQty(this.state.category,this.state.product,i,"-")} disabled={variant.num<=0?true:false}>
                            <i className="mdi mdi-minus"></i>
                          </Button>
                        </div>
                        <input name={"size"+i} placeholder="0" type="number" min="0" className="text-center form-control hide-arrows" onChange={(ev)=>this.updateQty(this.state.category,this.state.product,i,ev)} value={variant.num}/>

                        <div className="input-group-append">
                          <Button type="button" color="primary" onClick={(ev)=>this.updateQty(this.state.category,this.state.product,i,"+")}>
                            <i className="mdi mdi-plus"></i>
                          </Button>
                        </div>
                      </InputGroup>
                    </Col>
                    </Row>
                    
                    
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <Row className="w-100">
                  <Col className="align-self-center">
                    <strong>Totaal: {data.variants.reduce((acc,val)=>(acc + (parseInt(val.num)|| 0)),0)}</strong>
                  </Col>
                  <Col className="text-right">
                  <button
                    type="button"
                    className="btn btn-success waves-effect waves-light"
                    onClick={()=>{this.setState({variants:false})}}
                  >
                    Klaar
                  </button>
                  </Col>
              </Row>
              
            </div>
          </Modal>:""}
        </div>
      </React.Fragment>
    );
  }
}

export default Store;
