import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import { Loading, LoadError } from "../../components/Loading"
import apiCall from "../../helpers/apiCall"
import { Datatable } from "../../components/Datatable"
function badges(status, shippingMethod) {
  if(status==="open"){
    return <span className="badge-soft-primary badge font-size-14 p-2">
      Openstaand
    </span>
  } else if(status==="paid") {
    return <span className="badge-soft-success badge font-size-14 p-2">
      Betaald
    </span>
  } else if(status==0) {
    return <span className="badge-soft-danger badge font-size-14 p-2">
      Nieuw
    </span>
  } else if(status==1) {
    return <span className="badge-soft-warning badge font-size-14 p-2">
      In behandeling
    </span>
  } else if(status==2) {
    return <span className="badge-soft-success badge font-size-14 p-2">
      {shippingMethod==="ship"?"Verzonden":"Klaar voor ophalen"}
    </span>
  } else if(status===3){
    return <span className="badge-soft-success badge font-size-14 p-2">
      {shippingMethod==="ship"?"Verzonden":"Opgehaald"}
    </span>
  }
}

class ClientOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loaded:0
    };
  }

  componentDidMount() {
    apiCall("api/orders",this.props.token).then(async data => {

      var resp = data.map((item)=>{
        // var ordered = new Date(item.orderedOn).toISOString().split('T')[0].split("-")
        return {
        ...item,
        // total:'€ '+item.total,
        // paymentStatus:badges(item.paymentStatus),
        // orderStatus: badges(item.orderStatus),
        // orderedOn: ordered[2]+"-"+ordered[1]+"-"+ordered[0],
        view:item.invoiceNo
      }})
      this.setState({data:resp, loaded:1})
    }).catch((err)=>{
      this.setState({loadError:1, loaded:1})
      console.error(err)
    })


  }
    // fetching the GET route from the Express server which matches the GET route from server.js
  callBackendAPI = async () => {
    const response = await fetch('/api/orders');
    const body = await response.json();

    if (response.status !== 200) {
      throw Error(body.message)
    }
    return body;
  };

  render() {
    if(this.state.loaded==0){
      return <Loading/>
    }
    const columns = [
      {
        Header: "Ordernummer",
        accessor: 'invoiceNo',
      },{
        Header: "Status betaling",
        accessor: 'paymentStatus',
        Cell: ({value, row, column}) => {
          return badges(value)
        }
      },{
        Header: "Totaal",
        accessor: 'total',
        Cell: ({value, row, column}) => {
          return '€ '+value
        },
      },{
        Header: "Besteld door",
        accessor: 'orderedBy',
        Cell: ({value, row, column}) => {
          return value
        },
      },{
        Header: "Besteld op",
        accessor: 'orderedOn',
        Cell: ({value, row, column}) => {
          var ordered = new Date(value).toISOString().split('T')[0].split("-")
          return ordered[2]+"-"+ordered[1]+"-"+ordered[0]
        },
      },{
        Header: "Status",
        accessor: 'orderStatus',
        shippingMethodAccessor:"shippingMethod",
        Cell: ({value, row, column}) => {
          return badges(value, row.original[column.shippingMethodAccessor])
        },
      },{
        Header: "Bekijken",
        accessor: 'view',
        Cell: ({value, row, column}) => {
          return <Button size="sm" tag={Link} color="primary" outline to={"/orders/"+value} >Bekijken</Button>
        },
        sortable: false,
      },
    ]

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Bestellingen</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">Bestellingen</li>
                </ol>
              </div>
            </Col>
          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  {/* <MDBDataTable entries={100} searchLabel={"Zoeken"} displayEntries={false} responsive hover data={data} /> */}
                  <Datatable columns={columns} data={this.state.data}/>
                </div>
              </div>
            </div>
          </div>
        </div>

      </React.Fragment>
    );
  }
}

export default ClientOrders;
