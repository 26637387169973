import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { FORGET_PASSWORD } from "./actionTypes";
import { resetPasswordSuccess, resetPasswordError } from "./actions";

//AUTH related methods
import { getBackend } from '../../../helpers/authUtils';
const Backend = getBackend();
//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    const response = yield call(Backend.resetPassword, user.email, user.password, user.token);
    if (response) {
      yield put(
        resetPasswordSuccess(
          "Je wachtwoord is gereset."
        )
      );
      window.location = "/login"
    }
  } catch (error) {
    console.error(error)
    yield put(resetPasswordError(error));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
