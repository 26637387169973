
const host = process.env.REACT_APP_API

const getBackend = () => {
  return {
    loginUser: (email, password) => {
      return new Promise((resolve, reject) => {
        fetch(host + "auth/login", {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email: email, password:password }),
        })
          .then(async response => {

            if (!response.ok) {
              if (response.status === 400) {
                reject("Please fill all the fields correctly!")
              } else if (response.status === 401) {
                reject("Invalid email and password combination.")
              } else {
                reject("Something went wrong! Please try again later.")
              }
            } else {
              const data = await response.json()
              // setUserContext(oldValues => {
              //   return { ...oldValues, token: data.token }
              // })
              resolve({token:data.token, type:data.userType, logo:data.logo})
            }
          })
          .catch(error => {
            reject("Kan geen verbinding maken met de server. Probeer het later opnieuw")
          })
      });
    },
    refreshToken: (email, password) => {
      return new Promise((resolve, reject) => {
        console.log("Updating token!")
        fetch(host + "auth/refreshToken", {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json"},
        }).then(async response => {
          if (response.ok) {
            const data = await response.json()
            resolve({token:data.token, type:data.userType, logo: data.logo})
          } else {
            resolve({token:null, type:null})
          }

        }).catch(error => {
          reject("Kan geen verbinding maken met de server. Probeer het later opnieuw")
        })
      });
    },
    logout: (email, password) => {
      return new Promise((resolve, reject) => {
        console.log("Updating token!")
        fetch(host + "auth/logout", {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json"},
        }).then(async response => {
          if (response.ok) {
            const data = await response.json()
            resolve(null)
          } else {
            resolve(null)
          }

        }).catch(error => {
          reject("Kan geen verbinding maken met de server. Probeer het later opnieuw")
        })
      });
    },
    resetPassword: (email, password, token) => {
      return new Promise((resolve, reject) => {
        console.log("Updating token!")
        fetch(host + "auth/resetPassword/"+token, {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email: email, password:password }),
        }).then(async response => {
          if (response.ok) {
            const data = await response.json()
            resolve(200)
          } else {
            if(response.status == 400){
              reject((await response.json()).error)
            } else if(response.status == 402){
              reject((await response.json()).error)
            } else {
              reject("Er is een serverfout opgetreden, probeer het later opnieuw")
            }
          }

        }).catch(error => {
          reject("Kan geen verbinding maken met de server. Probeer het later opnieuw")
        })
      });
    }
  }
}

export { getBackend };
