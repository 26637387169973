import { toast } from 'react-toastify';

const host = process.env.REACT_APP_API

export default function(endpoint,token,type,data){
  return new Promise((resolve,reject) => {
    if(type !== undefined && type==="post"){
      fetch(host + endpoint, {
        method: "POST",
        credentials: "include",
        // Pass authentication token as bearer token in header
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body:JSON.stringify(data)
      }).then(async response => {
        if (response.ok) {
          const data = await response.json()
          resolve(data)
        } else {
          if (response.status === 401) {
            reject(response)
            //window.location.reload()
          } else {
            if(response.status===500){
              console.error(response)
              toast.error("Er is een een onverwachte fout opgetreden, probeer het later opnieuw.")
            }
            reject(response)
          }
        }
      }).catch((err)=>{
        toast.error("Kan geen verbinding maken met de server.")
        reject(err)
      })
    } else if(type==="multipart") {
      fetch(host + endpoint, {
        method: "POST",
        credentials: "include",
        // Pass authentication token as bearer token in header
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body:data
      }).then(async response => {
        if (response.ok) {
          const data = await response.json()
          resolve(data)
        } else {
          if (response.status === 401) {
            reject(response.status)
            //window.location.reload()
          } else {
            reject(response.status)
          }
        }
      }).catch((err)=>{
        reject(err)
      })
    } else {
      fetch(host + endpoint, {
        method: "GET",
        credentials: "include",
        // Pass authentication token as bearer token in header
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }).then(async response => {
        if (response.ok) {
          const data = await response.json()
          resolve(data)
        } else {
          if (response.status === 401) {
            window.location.reload()
          } else {
            reject(response.status)
          }
        }
      }).catch((err)=>{
        reject(err)
      })
    }
  })
}
