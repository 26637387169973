import React, { Component } from "react";
import { Row, Col, Card, CardBody, Alert } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

import Loader from "../../components/Loader";
// action
import { resetPassword } from "../../store/actions";

import zxcvbn from "zxcvbn";


import logoSm from "../../assets/images/logo-dark.png";
import logosmImg from "../../assets/images/logo-sm.png";

const getStrengthColor = strength => {
  switch (strength) {
    case 0:
      return "danger";
    case 1:
      return "danger";
    case 2:
      return "warning";
    case 3:
      return "warning";
    case 4:
      return "success";
    default:
      return "primary";
  }
};
class resetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      strengthcolor:getStrengthColor(-1)
    };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    this.props.resetPassword(values, this.props.history);
  }

  passwordChange = (ev) => {
    var password = ev.target.value

    const { score } = zxcvbn(password);
    this.setState({
      strengthcolor:getStrengthColor(score)
    })
  }

  validate = (password) =>{
    const { score } = zxcvbn(password);
    if(score < 4)
      return "Wachtwoord niet sterk genoeg"
    return true
  }

  render() {
    return (
      <React.Fragment>
        <div className="account-pages my-5 pt-5">
          <div className="container">
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <div className="position-relative">
                  <Card className="overflow-hidden">
                    <div className="">
                      <div className="text-primary text-center p-2 pt-4">

                        <Link to="/" className="logo">
                          <img src={logoSm} height="96" alt="logo" />
                        </Link>
                      </div>
                    </div>

                    <CardBody className="p-4">
                      <div className="p-3">

                        <AvForm
                          className="form-horizontal mt-4"
                          onValidSubmit={this.handleValidSubmit}
                        >
                        <AvField
                          name="token"
                          type="hidden"
                          required
                          value={this.props.match.params.id}
                        />
                          {this.props.forgetError ? (
                            <Alert color="danger">{this.props.forgetError}</Alert>
                          ) : null}

                          <div className="form-group">
                            <AvField
                              name="email"
                              label="Email"
                              className="form-control"
                              value=""
                              placeholder="Email"
                              type="email"
                              validate={{required:{valid:true, errorMessage:"Dit veld is verplicht."}}}
                            />
                          </div>
                          <div className="form-group">
                            <AvField
                              name="password"
                              label="Wachtwoord"
                              type="password"
                              validate={{required:{valid:true, errorMessage:"Dit veld is verplicht."},myValidation: this.validate}}
                              value=""
                              placeholder="Wachtwoord"
                              onChange={this.passwordChange}
                              style={{borderBottom:"3px solid var(--"+this.state.strengthcolor+")"}}
                            />
                          </div>
                          <div className="form-group">
                            <AvField
                              name="confirmPassword"
                              label="Herhaal wachtwoord"
                              type="password"
                              validate={{match:{value:'password',errorMessage:"Wachtwoorden komen niet overeen."},required:{valid:true, errorMessage:"Dit veld is verplicht."}}}
                              value=""
                              placeholder="Wachtwoord"
                            />
                          </div>

                          <Row className="form-group">
                            <Col sm={12} className="text-center">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                              >
                                Reset Wachtwoord
                              </button>
                            </Col>
                            <Col sm={12} className="text-center">
                              {this.props.loading ?
                              <div className="spinner-grow text-center text-primary m-1" role="status">
                                  <span className="sr-only">Loading...</span>
                              </div>: null}
                            </Col>
                          </Row>
                        </AvForm>
                      </div>
                    </CardBody>
                  </Card>
                </div>
                <div className="mt-5 text-center">
                  <p className="mb-0">
                    © {new Date().getFullYear()} LOGOnow.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword;
  return { forgetError, forgetSuccessMsg };
};

export default withRouter(
  connect(mapStatetoProps, { resetPassword })(resetPasswordPage)
);
