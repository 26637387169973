import React from "react";
import { Route, Redirect } from "react-router-dom";

//AUTH related methods
// import { getFirebaseBackend } from "../helpers/authUtils";
const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  token,
  type,
  adminRoute,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      //const fireBaseBackend = getFirebaseBackend();
      if (isAuthProtected && !token) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }

      if (!adminRoute && type === "admin") {
        return (
          <Redirect
            to={{ pathname: "/admin/dashboard", state: { from: props.location } }}
          />
        );
      }

      if (adminRoute && type === "user") {
        return (
          <Redirect
            to={{ pathname: "/store", state: { from: props.location } }}
          />
        );
      }
      props.token = token
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }}
  />
);

export default AppRoute;
