import React, { Component } from "react";
import SettingMenu from "../Shared/SettingMenu";
import {
  Row,
  Col,
  Button,
  Label,
  Input,
  Card,
  CardBody,
  CardText,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
// Custom Scrollbar
import SimpleBar from "simplebar-react";
import Products from "./Products";
import User from "./User";
import { MDBDataTable } from "mdbreact";
// import images
import Image from "./Image";
import "chartist/dist/scss/chartist.scss";

import { Loading, LoadError } from "../../components/Loading";
import apiCall from "../../helpers/apiCall";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  AvForm,
  AvField,
  AvInput,
  AvCheckboxGroup,
  AvCheckbox,
} from "availity-reactstrap-validation";

const host = process.env.REACT_APP_API;
var prefix = host + "images/";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLocation: 0,
      id: decodeURIComponent(this.props.match.params.id),
      data: null,
      loadError: 0,
      loaded: 0,
      // image:"http://via.placeholder.com/200",
      showProducts: true,
      editLink: false,
    };
  }

  componentDidMount() {
    //toast.info("Wow so easy!")
    if (this.state.id != "new") {
      apiCall(
        "api/admin/customers/" + encodeURIComponent(this.state.id),
        this.props.token
      )
        .then(async (data) => {
          this.setState({
            data: { ...data, logo: prefix + data.logo },
            loaded: true,
            editLink: data.link == "",
          });
        })
        .catch((err) => {
          this.setState({ loadError: true, loaded: true });
          console.error(err);
        });
    } else {
      this.setState({
        data: {
          name: "",
          customerNumber: "",
          logo: "",
          link: "",
          address: {
            city: "",
            email: "",
            street: "",
            country: "NL",
            houseNo: "",
            addition: "",
            lastName: "",
            firstName: "",
            postalCode: "",
          },
          paymentTypes: {
            direct: {
              limit: 0,
              allowed: true,
            },
            account: {
              limit: 0,
              allowed: true,
            },
          },
          categories: [],
          user: null,
        },
        showProducts: false,
        loaded: true,
        editLink: true,
      });
    }
  }

  toggleLocation(tab) {
    if (this.state.activeLocation !== tab) {
      this.setState({
        activeLocation: tab,
      });
    }
  }

  updateLocations = (i, event) => {
    var data = this.state.data;
    data.locations[i][event.target.attributes.name.value] = event.target.value;
    this.setState({ data: data });
  };
  updateData = (event) => {
    var data = this.state.data;
    data[event.target.attributes.name.value] = event.target.value;
    this.setState({ data: data });
  };

  updateAdress = (event) => {
    var data = this.state.data;
    data.address[event.target.attributes.name.value] = event.target.value;
    this.setState({ data: data });
  };

  updatePaymentTypes = (type, event) => {
    var data = this.state.data;
    if (event.target.name === "allowed") {
      data.paymentTypes[type].allowed = event.target.checked;
    } else {
      data.paymentTypes[type].limit = event.target.value;
    }
    this.setState({ data: data });
  };

  save = () => {
    var data = JSON.parse(JSON.stringify(this.state.data));
    delete data.categories;

    const formData = new FormData();

    if (data.logo.startsWith("blob")) {
      formData.append(data.logo, this.state.data.blob);
    } else {
      data.logo = data.logo.slice(prefix.length);
    }

    formData.append("data", JSON.stringify(data));

    // apiCall("api/admin/customers/"+encodeURIComponent(this.props.id)+"/products",this.props.token,"multipart", formData).then(async data => {
    apiCall(
      "api/admin/customers/" + this.props.match.params.id,
      this.props.token,
      "multipart",
      formData
    )
      .then(async (data) => {
        if (data.error) {
          if (data.error === "name_taken") {
            toast.error("Klant naam is al in gebruik.");
            return;
          } else if (data.error === "customerNumber_taken") {
            toast.error("Klantnummer is al in gebruik.");
            return;
          }
        }
        toast.success("Klantgegevens opgeslagen.");
        this.props.history.push(
          "/admin/customers/" + encodeURIComponent(this.state.data.name)
        );
        this.setState({
          showProducts: true,
          id: this.state.data.name,
          editLink: this.state.data.link == "",
        });
      })
      .catch((err) => {
        toast.error(
          "Er is een fout opgetreden bij het opslaan van klantgegevens."
        );
        console.error(err);
        this.props.history.push(
          "/admin/customers/" + encodeURIComponent(this.state.data.name)
        );
      });
  };

  toggle = (toggle) => {
    this.setState({ open: toggle });
  };

  croppedImage = (img, blob) => {
    var data = this.state.data;
    data.logo = img;
    data.blob = blob;
    this.setState({ data: data });
  };

  setPlaceholderImage = () => {
    var data = this.state.data;
    data.logo = prefix + "placeholder.jpeg";
    this.setState({ data: data });
  };

  savedData = (newData) => {
    this.setState({ data: { ...this.state.data, categories: newData } });
  };

  render() {
    if (!this.state.loaded) {
      return <Loading />;
    }
    if (this.state.loadError) {
      return (
        <LoadError text="Er is een fout opgetreden bij het laden van klant." />
      );
    }

    const address = this.state.data.address;
    const paymentTypes = this.state.data.paymentTypes;
    return (
      <React.Fragment>
        <Image
          open={this.state.open}
          toggle={this.toggle}
          croppedImage={this.croppedImage}
        />
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={12}>
              <div className="page-title-box">
                <h4 className="font-size-18">Klanten</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/admin">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/customers">Klanten</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {this.state.id == "new" ? "Nieuw" : this.state.id}
                  </li>
                </ol>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Card>
                <CardBody>
                  <AvForm onValidSubmit={this.save}>
                    <Row>
                      <Col sm={9}>
                        <h4 className="">{this.state.data.name}</h4>
                        {this.state.showProducts ? (
                          <Button
                            color="primary"
                            tag={Link}
                            to={
                              "/admin/customers/" +
                              encodeURIComponent(this.state.id) +
                              "/store"
                            }
                            outline
                          >
                            <i className="dripicons-store mr-2"></i>Ga naar
                            klant pagina
                          </Button>
                        ) : (
                          ""
                        )}
                        <div className="mt-3">
                          {this.state.showProducts && !this.state.editLink ? (
                            <>
                              <Button
                                color="primary"
                                target="_blank"
                                tag={Link}
                                to={{ pathname: this.state.data.link }}
                                outline
                              >
                                <i className="mdi mdi-google-drive"></i> Ga naar
                                Drive
                              </Button>
                              <Button
                                color="primary"
                                className="ml-1"
                                target="_blank"
                                onClick={() => {
                                  this.setState({ editLink: true });
                                }}
                                outline
                              >
                                <i className="mdi mdi-pencil"></i>
                              </Button>
                            </>
                          ) : (
                            <>
                              <Label for="link">Drive Link</Label>
                              <AvField
                                validate={{
                                  pattern: {
                                    value:
                                      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
                                    errorMessage: "Voer een geldige url in.",
                                  },
                                }}
                                type="text"
                                name="link"
                                onChange={this.updateData}
                                value={this.state.data.link}
                              />
                            </>
                          )}
                        </div>
                      </Col>
                      <Col sm={3} className="text-right">
                        <div className="p-2 position-relative mb-0">
                          <img
                            src={this.state.data.logo}
                            onError={this.setPlaceholderImage}
                            className="w-100 card-img-top img-fluid rounded-top"
                          />
                          <div class={"overlay"}>
                            <div>
                              <Button
                                color="primary"
                                onClick={() => {
                                  this.toggle(true);
                                }}
                              >
                                Afbeelding uploaden
                              </Button>
                            </div>
                          </div>
                        </div>
                        {/*}<img  onClick={()=>{this.toggle(true)}} width="200px"/>
                      <Button onClick={()=>{this.toggle(true)}}>test</Button>*/}
                      </Col>
                      <Col xl={6}>
                        <Label for="name">Bedrijf</Label>
                        <AvField
                          validate={{
                            required: {
                              valid: true,
                              errorMessage: "Dit veld is verplicht.",
                            },
                          }}
                          type="text"
                          name="name"
                          value={this.state.data.name}
                          onChange={(ev) => this.updateData(ev)}
                          className=""
                        />
                      </Col>
                      <Col xl={6}>
                        <Label for="customerNumber">Klantnummer</Label>
                        <AvField
                          validate={{
                            required: {
                              valid: true,
                              errorMessage: "Dit veld is verplicht.",
                            },
                          }}
                          type="text"
                          name="customerNumber"
                          value={this.state.data.customerNumber}
                          onChange={(ev) => this.updateData(ev)}
                          className=""
                        />
                      </Col>
                      <Col sm="6" className="">
                        <Label for="company">Voornaam</Label>
                        <AvField
                          validate={{
                            required: {
                              valid: true,
                              errorMessage: "Dit veld is verplicht.",
                            },
                          }}
                          type="text"
                          name="firstName"
                          onChange={(ev) => this.updateAdress(ev)}
                          value={address.firstName}
                        />
                      </Col>
                      <Col sm="6" className="">
                        <Label for="company">Achternaam</Label>
                        <AvField
                          validate={{
                            required: {
                              valid: true,
                              errorMessage: "Dit veld is verplicht.",
                            },
                          }}
                          type="text"
                          name="lastName"
                          onChange={(ev) => this.updateAdress(ev)}
                          value={address.lastName}
                        />
                      </Col>
                      <Col sm="12" className="">
                        <Label for="company">Factuur email</Label>
                        <AvField
                          // validate={{
                          //   required: {
                          //     valid: true,
                          //     errorMessage: "Dit veld is verplicht.",
                          //   },
                          //   pattern: {
                          //     valid: true,
                          //     value: new RegExp(
                          //       /^(|([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+([,.](([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5}){1,25})+)*$/g
                          //     ),
                          //     errorMessage: "Voer geldige email adressen in.",
                          //   },
                          // }}
                          type="text"
                          name="email"
                          onChange={(ev) => this.updateAdress(ev)}
                          value={address.email}
                        />
                      </Col>
                      <Col sm="6" className="">
                        <Label for="company">Straat</Label>
                        <AvField
                          validate={{
                            required: {
                              valid: true,
                              errorMessage: "Dit veld is verplicht.",
                            },
                          }}
                          type="text"
                          name="street"
                          onChange={(ev) => this.updateAdress(ev)}
                          value={address.street}
                        />
                      </Col>
                      <Col sm="3" className="">
                        <Label for="company">Huisnummer</Label>
                        <AvField
                          validate={{
                            required: {
                              valid: true,
                              errorMessage: "Dit veld is verplicht.",
                            },
                          }}
                          type="text"
                          name="houseNo"
                          onChange={(ev) => this.updateAdress(ev)}
                          value={address.houseNo}
                        />
                      </Col>
                      <Col sm="3" className="">
                        <Label for="company">Toevoeging</Label>
                        <AvField
                          type="text"
                          name="addition"
                          onChange={(ev) => this.updateAdress(ev)}
                          value={address.addition}
                        />
                      </Col>
                      <Col sm="4" className="">
                        <Label for="company">Postcode</Label>
                        <AvField
                          validate={{
                            required: {
                              valid: true,
                              errorMessage: "Dit veld is verplicht.",
                            },
                          }}
                          type="text"
                          name="postalCode"
                          onChange={(ev) => this.updateAdress(ev)}
                          value={address.postalCode}
                        />
                      </Col>
                      <Col sm="4" className="">
                        <Label for="company">Plaats</Label>
                        <AvField
                          validate={{
                            required: {
                              valid: true,
                              errorMessage: "Dit veld is verplicht.",
                            },
                          }}
                          type="text"
                          name="city"
                          onChange={(ev) => this.updateAdress(ev)}
                          value={address.city}
                        />
                      </Col>
                      <Col sm="4" className="">
                        <Label for="company">Land</Label>
                        <select class="form-control" value="NL">
                          <option disabled value="NL">
                            Nederland
                          </option>
                        </select>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="2" className="">
                        <Label for="company">Op rekening afrekenen</Label>
                        <AvCheckboxGroup name="checkboxExample">
                          <AvCheckbox
                            label="Toegestaan"
                            value="true"
                            checked={paymentTypes.account.allowed}
                            name="allowed"
                            onChange={(ev) =>
                              this.updatePaymentTypes("account", ev)
                            }
                          />
                        </AvCheckboxGroup>
                        <AvField
                          placeholder="0"
                          name="limit_account"
                          type="number"
                          label="Limiet in euros"
                          helpMessage="0 voor geen limiet"
                          value={paymentTypes.account.limit}
                          onChange={(ev) =>
                            this.updatePaymentTypes("account", ev)
                          }
                        />
                      </Col>
                      <Col sm="2" className="">
                        <Label for="company">Direct afrekenen</Label>
                        <AvCheckboxGroup name="checkboxExample">
                          <AvCheckbox
                            label="Toegestaan"
                            value="true"
                            checked={paymentTypes.direct.allowed}
                            name="allowed"
                            onChange={(ev) =>
                              this.updatePaymentTypes("direct", ev)
                            }
                          />
                        </AvCheckboxGroup>
                        <AvField
                          placeholder="0"
                          name="limit_direct"
                          type="number"
                          label="Limiet in euros"
                          helpMessage="0 voor geen limiet"
                          value={paymentTypes.direct.limit}
                          onChange={(ev) =>
                            this.updatePaymentTypes("direct", ev)
                          }
                        />
                      </Col>
                    </Row>
                    <Button type="submit" color="primary">
                      Opslaan
                    </Button>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>

            {this.state.showProducts ? (
              <Col xl={12}>
                <Card>
                  <CardBody>
                    <User
                      user={this.state.data.user}
                      id={this.state.id}
                      token={this.props.token}
                    />
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}
            {this.state.showProducts ? (
              <Col xl={12}>
                <Card>
                  <CardBody>
                    <Products
                      token={this.props.token}
                      id={this.state.id}
                      data={this.state.data.categories}
                      savedData={this.savedData}
                    />
                  </CardBody>
                </Card>
              </Col>
            ) : (
              ""
            )}
          </Row>
          {/*}<Row>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-left mini-stat-img mr-4">
                      <img src={servicesIcon1} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Orders
                    </h5>
                    <h4 className="font-weight-medium font-size-24">
                      1,685{" "}
                      <i className="mdi mdi-arrow-up text-success ml-2"></i>
                    </h4>
                    <div className="mini-stat-label bg-success">
                      <p className="mb-0">+ 12%</p>
                    </div>
                  </div>
                  <div className="pt-2">
                    <div className="float-right">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1">Since last month</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-left mini-stat-img mr-4">
                      <img src={servicesIcon2} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Revenue
                    </h5>
                    <h4 className="font-weight-medium font-size-24">
                      52,368{" "}
                      <i className="mdi mdi-arrow-down text-danger ml-2"></i>
                    </h4>
                    <div className="mini-stat-label bg-danger">
                      <p className="mb-0">- 28%</p>
                    </div>
                  </div>
                  <div className="pt-2">
                    <div className="float-right">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>

                    <p className="text-white-50 mb-0 mt-1">Since last month</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-left mini-stat-img mr-4">
                      <img src={servicesIcon3} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Average Price
                    </h5>
                    <h4 className="font-weight-medium font-size-24">
                      15.8{" "}
                      <i className="mdi mdi-arrow-up text-success ml-2"></i>
                    </h4>
                    <div className="mini-stat-label bg-info">
                      <p className="mb-0"> 00%</p>
                    </div>
                  </div>
                  <div className="pt-2">
                    <div className="float-right">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>

                    <p className="text-white-50 mb-0 mt-1">Since last month</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} md={6}>
              <Card className="mini-stat bg-primary text-white">
                <CardBody>
                  <div className="mb-4">
                    <div className="float-left mini-stat-img mr-4">
                      <img src={servicesIcon4} alt="" />
                    </div>
                    <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                      Product Sold
                    </h5>
                    <h4 className="font-weight-medium font-size-24">
                      2436{" "}
                      <i className="mdi mdi-arrow-up text-success ml-2"></i>
                    </h4>
                    <div className="mini-stat-label bg-warning">
                      <p className="mb-0">+ 84%</p>
                    </div>
                  </div>
                  <div className="pt-2">
                    <div className="float-right">
                      <Link to="#" className="text-white-50">
                        <i className="mdi mdi-arrow-right h5"></i>
                      </Link>
                    </div>
                    <p className="text-white-50 mb-0 mt-1">Since last month</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>*/}
        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
