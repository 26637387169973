import ReactDOM from 'react-dom';
import React, { PureComponent , createRef} from 'react';
import ReactCrop from "../../components/Crop";
import DragAndDrop from "./DragAndDrop";
import { Modal,ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
class App extends PureComponent {
  constructor(props){
    super(props);
    this.file = createRef()
  }
  state = {
    src: null,
    crop: {
      unit: '%',
      width: 1000,
      aspect: 16 / 9
    },
    open:true
  };

  onSelectFile = (e, drag) => {
    let files
    if(drag === true) {
      files = e
    } else {
      files = e.target.files
    }
    if (files && files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(files[0]);
    }
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image,component) => {
    this.imageRef = image;
    this.componentRef = component
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const {croppedImageUrl, blob} = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl,blob });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    var scale = 1;
    if(crop.width * pixelRatio * scaleX > 1280){
      scale = 1280/(crop.width * pixelRatio * scaleX)
    }

    canvas.width = crop.width * pixelRatio * scaleX * scale;
    canvas.height = crop.height * pixelRatio * scaleY * scale;
    var offsetX = image.getBoundingClientRect().left - this.componentRef.getBoundingClientRect().left
    var offsetY = image.getBoundingClientRect().top - this.componentRef.getBoundingClientRect().top
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
    ctx.rect(0, 0, crop.width * pixelRatio * scaleX, crop.height * pixelRatio * scaleY);
    ctx.fillStyle="white"
    ctx.fill();
    ctx.drawImage(
      image,
      (Math.max(0, crop.x-offsetX)) * scaleX,
      (Math.max(0, crop.y-offsetY)) * scaleY,
      (Math.min(image.width, crop.x-offsetX+crop.width)) * scaleX,
      (Math.min(image.height, crop.x-offsetY+crop.height)) * scaleY,
      Math.max(0, offsetX-crop.x)*scaleX* scale,
      Math.max(0, offsetY-crop.y)*scaleY* scale,
      (Math.min(image.width, crop.x-offsetX+crop.width)) * scaleX * scale,
      (Math.min(image.height, crop.x-offsetY+crop.height)) * scaleY * scale
    );
    console.log(
      image,
      (Math.max(0, crop.x-offsetX)) * scaleX,
      (Math.max(0, crop.y-offsetY)) * scaleY,
      (Math.min(image.width, crop.x-offsetX+crop.width)) * scaleX,
      (Math.min(image.height, crop.x-offsetY+crop.height)) * scaleY,
      Math.max(0, offsetX-crop.x)*scaleX,
      Math.max(0, offsetY-crop.y)*scaleY,
      (Math.min(image.width, crop.x-offsetX+crop.width)) * scaleX * scale,
      (Math.min(image.height, crop.x-offsetY+crop.height)) * scaleY * scale
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error('Canvas is empty');
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(this.fileUrl);
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve({croppedImageUrl: this.fileUrl,blob:blob});
        },
        'image/jpeg',
        1
      );
    });
  }

  toggle = (toggle) => {
    this.props.toggle(toggle)
    if(toggle==false){
      this.setState({src:null})
    }
  }

  render() {
    const { crop, croppedImageUrl, src, blob } = this.state;

    return (
      <Modal size="lg" centered isOpen={this.props.open} toggle={()=>{this.toggle(false)}}>
        <ModalHeader toggle={()=>{this.toggle(false)}}>Afbeelding uploaden</ModalHeader>
        <ModalBody>
      <div className="App"  >
        <div>
          {/* <input type="file" style={{position:"absolute", top:"-100%", display:"none"}} accept="image/*" ref={this.file} onChange={this.onSelectFile} />
          <Button color="primary" onClick={()=>{this.file.current.click()}}>Upload Afbeelding</Button> */}

          {(!src && <DragAndDrop hide={src?true:false} onSelectFile={this.onSelectFile} />)}

        </div>
        {src && (
          <div className="position-relative w-100" style={{paddingTop:"calc(100% * 9 / 16)"}}>
          <ReactCrop
            src={src}
            crop={crop}
            style={{top:0,bottom:0,left:0,right:0 }}
            className="position-absolute"
            ruleOfThirds
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
          />
          </div>
        )}
        {/*croppedImageUrl && (
          <img alt="Crop" style={{ maxWidth: '100%', objectFit: "contain" }} src={croppedImageUrl} />
        )*/}
      </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" disabled={!croppedImageUrl} onClick={()=>{this.toggle(false); this.props.croppedImage(croppedImageUrl,blob)}}>Klaar</Button>{' '}
        <Button color="secondary" onClick={()=>{this.toggle(false)}}>Cancel</Button>
      </ModalFooter>
    </Modal>
    );
  }
}

export default App
