import React, { Component } from "react";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes
import { authProtectedRoutes, publicRoutes, authProtectedAdminRoutes } from "./routes/";
import AppRoute from "./routes/route";
import { refreshToken } from "./store/actions";
// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import adminLayout from "./components/adminLayout/";
import clientLayout from "./components/clientLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import { Loading } from "./components/Loading"
import {ToastContainer}  from 'react-toastify';
// Import scss
import "./assets/scss/theme.scss";

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/authUtils";

// const firebaseConfig = {
//   apiKey: "AIzaSyBUctS2mlPY58Y-E1AypSUz7OO0zhlBLC8",
//   authDomain: "themesbrand-admin.firebaseapp.com",
//   databaseURL: "https://themesbrand-admin.firebaseio.com",
//   projectId: "themesbrand-admin",
//   storageBucket: "themesbrand-admin.appspot.com",
//   messagingSenderId: "427667224207",
//   appId: "1:427667224207:web:3b97af80b8b4824619a2fa",
//   measurementId: "G-S4LDYNV7FY"
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getLayout = this.getLayout.bind(this);
  }
  componentDidMount() {
    this.verifyUser()
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (this.props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  verifyUser = () => {
    // console.log("Updating token!")
    // fetch("http://localhost:5000/" + "auth/refreshToken", {
    //   method: "POST",
    //   credentials: "include",
    //   headers: { "Content-Type": "application/json" },
    // }).then(async response => {
    //   if (response.ok) {
    //     const data = await response.json()
    //     setUserContext(oldValues => {
    //       return { ...oldValues, token: data.token }
    //     })
    //   } else {
    //     setUserContext(oldValues => {
    //       return { ...oldValues, token: null }
    //     })
    //   }
    //   // call refreshToken every 5 minutes to renew the authentication token.
    //   setTimeout(this.verifyUser, 5 * 60 * 1000)
    // })
    this.props.refreshToken("test")
    setTimeout(this.verifyUser, 5 * 60 * 1000)
  }


  render() {
    // const Layout = this.getLayout();
    if(this.props.token === undefined){
      return (<Loading/>)
    }
    return (
      <React.Fragment>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
      />
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                exact={route.exact}
                key={idx}
                adminRoute={false}
                type={undefined}
                isAuthProtected={false}
                token={this.props.token}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={clientLayout}
                component={route.component}
                key={idx}
                adminRoute={false}
                isAuthProtected={true}
                type={this.props.type}
                token={this.props.token}
              />
            ))}

            {authProtectedAdminRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={adminLayout}
                component={route.component}
                key={idx}
                adminRoute={true}
                isAuthProtected={true}
                type={this.props.type}
                token={this.props.token}
              />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {

  return {
    layout: state.Layout,
    token: state.Login.token,
    type: state.Login.type
  };
};

export default connect(mapStateToProps, { refreshToken })(App);
