import { LOGIN_USER, LOGIN_SUCCESS, LOGOUT_USER, LOGOUT_USER_SUCCESS, API_ERROR, REFRESH_TOKEN_SUCCESS } from './actionTypes';

const initialState = {
    error: null,
    loading: false,
    token: undefined
}

const login = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER:
            state = {
                ...state,
                loading: true
            }
            break;
        case LOGIN_SUCCESS:
            state = {
                ...state,
                loading: false,
                token: action.payload.token,
                type: action.payload.type,
                logo: action.payload.logo,
                error:null
            }
            break;
        case LOGOUT_USER:
            state = { ...state };
            break;
        case LOGOUT_USER_SUCCESS:
            state = { ...state,
            token: null, type:null, logo:null };
            break;
        case REFRESH_TOKEN_SUCCESS:
            state = { ...state, token: action.payload.token, type: action.payload.type, logo: action.payload.logo};
            break;
        case API_ERROR:
            state = { ...state, error: action.payload, loading: false };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default login;
