import ReactDOM from 'react-dom';
import React, { PureComponent , createRef} from 'react';
import ReactCrop from "../../components/Crop";
import { Modal,ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
class DragAndDrop extends React.Component {
  constructor(props){
    super(props);
    this.file = createRef()
  }
  state = {
    src: null,
    crop: {
      unit: '%',
      width: 1000,
      aspect: 16 / 9
    },
    open:true
  };

  //Drag and drop
  upload = React.createRef()
  dropRef = React.createRef()
  handleDrag = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  dragCounter = 0
  handleDragIn = (e) => {
    console.log("dragIn")
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter++
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({drag: true})
    }
  }
  handleDragOut = (e) => {
    console.log("dragOut",e)
    e.preventDefault()
    e.stopPropagation()
    this.dragCounter--
    if (this.dragCounter === 0) {
      this.setState({drag: false})
    }
  }
  handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({drag: false})
    console.log(e.dataTransfer.files.length)
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      //this.props.handleDrop(e.dataTransfer.files)
      e.dataTransfer.clearData()
    }

    this.props.onSelectFile(e.dataTransfer.files, true)
  }
  componentDidMount() {
    console.log(this.dropRef)
    let div = this.dropRef.current
    div.addEventListener('dragenter', this.handleDragIn)
    div.addEventListener('dragleave', this.handleDragOut)
    div.addEventListener('dragover', this.handleDrag)
    div.addEventListener('drop', this.handleDrop)
  }
  componentWillUnmount() {
    let div = this.dropRef.current
    div.removeEventListener('dragenter', this.handleDragIn)
    div.removeEventListener('dragleave', this.handleDragOut)
    div.removeEventListener('dragover', this.handleDrag)
    div.removeEventListener('drop', this.handleDrop)
  }


  render() {
    return (
        <div className="position-relative w-100" style={{paddingTop:"calc(100% * 9 / 16)"}}>
          <div style={{top:0,bottom:0,left:0,right:0 }}
            className="position-absolute"
            ref={this.dropRef}
          >
            <div className="h-100" style={{border: 'dashed grey 2px', minHeight: "300px"}}>
            {this.state.drag &&
              <div
                style={{
                  border: 'dashed grey 2px',
                  backgroundColor: 'rgba(255,255,255,.8)',
                  position: 'absolute',
                  pointerEvents:"none",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  zIndex: 9999
                }}
              >
                <div className="position-absolute text-muted top-50 start-50 translate-middle">
                  <h6>Zet het bestand hier neer.</h6>
                </div>
              </div>
            }
            <div style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}>
            <div className="position-absolute top-50 start-50 translate-middle text-center">
              {/* <FontAwesomeIcon icon={faUpload} className="text-muted mb-2" size="4x"/> */}
              <h6 className="d-block mb-2 text-muted">Sleep bestanden om te uploaden</h6>
              <div className="d-block"><h6 className=" d-inline mb-2 text-muted">of klik op </h6><a className="btn ms-2 btn-outline-primary" onClick={()=>{this.upload.current.click()}}>Upload</a></div>
              {/* <input type="file" name="imgCollection"className="d-none" ref={this.upload} onChange={this.onFileChange} /> */}
              <input type="file" style={{position:"absolute", top:"-100%", display:"none"}} accept="image/*" ref={this.upload} onChange={this.props.onSelectFile} />

              {/*<div><Button color="success" outline>Upload</Button></div>*/}
            </div>
            </div>
            </div>
            </div>
            </div>
    );
  }
}

export default DragAndDrop
