import React, { Component } from "react";
import { Row, Col, Button, Nav, NavItem } from "reactstrap";
import { Link } from "react-router-dom";

import { Loading, LoadError, Loader } from "../../components/Loading";

import apiCall from "../../helpers/apiCall";

import { toast } from "react-toastify";

import LoadingButton from "../../components/LoadingButton";

const host = process.env.REACT_APP_API;
var prefix = host + "images/";

function badges(status, paymentType) {
  var prefix = "Direct";
  if (paymentType == "account") {
    prefix = "Op rekening: ";
  }
  if (status === "open") {
    return (
      <span className="badge-soft-primary badge font-size-14 p-2">
        {prefix} Openstaand
      </span>
    );
  } else if (status === "paid") {
    return (
      <span className="badge-soft-success badge font-size-14 p-2">
        {prefix} Betaald
      </span>
    );
  } else if (status == 0) {
    return (
      <span className="badge-soft-danger badge font-size-14 p-2">Nieuw</span>
    );
  } else if (status == 1) {
    return (
      <span className="badge-soft-warning badge font-size-14 p-2">
        In behandeling
      </span>
    );
  } else if (status == 2) {
    return (
      <span className="badge-soft-success badge font-size-14 p-2">
        Verzonden
      </span>
    );
  } else if (status === 3) {
    return (
      <span className="badge-soft-dark badge font-size-14 p-2">
        Gearchiveerd
      </span>
    );
  }
}
class ClientOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loaded: 0,
      id: this.props.match.params.id,
      notFound: false,
      width: 0,
      labelLoading: false,
    };
  }

  componentDidMount() {
    fetch(host + "api/admin/orders/" + this.state.id, {
      method: "GET",
      credentials: "include",
      // Pass authentication token as bearer token in header
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(async (response) => {
        if (response.ok) {
          var data = await response.json();
          this.setState({ data: data, code: 200 });
        } else {
          this.setState({ code: response.status });
          if (response.status === 401) {
            // Edge case: when the token has expired.
            // This could happen if the refreshToken calls have failed due to network error or
            // User has had the tab open from previous day and tries to click on the Fetch button
            window.location.reload();
          } else {
          }
        }
      })
      .catch((err) => {
        //handle error
      });
  }

  setStatus = (status) => {
    var data = this.state.data;
    if (data.status === status) {
      return;
    }
    var oldStatus = data.status;
    data.status = status;
    this.setState({ data: data });
    apiCall(
      "api/admin/orders/" + encodeURIComponent(this.state.id) + "/status",
      this.props.token,
      "post",
      { status: status }
    )
      .then(async (data) => {
        this.setState({ data: data });
        toast.success("Status geupdatet.");
      })
      .catch((err) => {
        // this.setState({loadError:1, loaded:1})
        data.status = oldStatus;
        this.setState({ data: data });
        toast.error("Er is een fout opgetreden bij het updaten van status.");
        console.error(err);
      });
  };

  setPayment = (status) => {
    var data = this.state.data;
    if (data.paymentStatus === status) {
      return;
    }
    var oldStatus = data.paymentStatus;
    data.paymentStatus = status;
    this.setState({ data: data });
    apiCall(
      "api/admin/orders/" + encodeURIComponent(this.state.id) + "/payment",
      this.props.token,
      "post",
      { status: status }
    )
      .then(async (data) => {
        this.setState({ data: data });
        toast.success("Betaling geupdatet.");
      })
      .catch((err) => {
        // this.setState({loadError:1, loaded:1})
        data.paymentStatus = oldStatus;
        this.setState({ data: data });
        toast.error("Er is een fout opgetreden bij het updaten van betaling.");
        console.error(err);
      });
  };

  createLabel = (status) => {
    this.setState({ labelLoading: true });
    apiCall(
      "api/admin/orders/" + encodeURIComponent(this.state.id) + "/label",
      this.props.token,
      "post"
    )
      .then(async (data) => {
        this.setState({ data: data, labelLoading: false });
        toast.success("Label aangemaakt");
        window.open(
          host +
            "api/admin/orders/" +
            encodeURIComponent(this.state.id) +
            "/label?access_token=" +
            this.props.token,
          "_blank",
          ["noopener"]
        );
      })
      .catch((err) => {
        // this.setState({loadError:1, loaded:1})
        if (err.status == 406) {
          toast.error(
            <>
              De verzendmethode bestaat niet of is ongelig, ga naar{" "}
              <Link to="/admin/settings" target="_blank">
                Instellingen
              </Link>{" "}
              om het aan te passen.
            </>
          );
        } else if (err.status == 400) {
          toast.error("De adresgegevens zijn ongeldig.");
        } else {
          toast.error(
            "Er is een fout opgetreden bij het aanmaken van het label."
          );
        }
        console.error(err);
        this.setState({ labelLoading: false });
      });
  };

  getLabel = (status) => {
    window.open(
      host +
        "api/admin/orders/" +
        encodeURIComponent(this.state.id) +
        "/label?access_token=" +
        this.props.token,
      "_blank",
      ["noopener"]
    );
  };

  paymentBtn = () => {
    if (this.state.data.paymentType == "account") {
      if (this.state.data.paymentStatus == "open") {
        return (
          <Button
            color="success"
            key="open"
            onClick={() => {
              this.setPayment("paid");
            }}
          >
            Markeer als Betaald
          </Button>
        );
      } else if (this.state.data.paymentStatus == "paid") {
        return (
          <Button
            color="primary"
            key="paid"
            onClick={() => {
              this.setPayment("open");
            }}
          >
            Markeer als Openstaand
          </Button>
        );
      }
    }
  };

  labelBtn = () => {
    //{this.state.data.parcelStatus}
    if (this.state.data.shippingMethod !== "ship") {
      return null;
    }
    if (this.state.data.parcelId != null) {
      return (
        <>
          <Button
            className="mr-2"
            color="success"
            onClick={() => {
              this.getLabel();
            }}
          >
            Label openen
          </Button>
        </>
      );
    } else {
      return (
        <LoadingButton
          className="mr-2"
          color="success"
          loading={this.state.labelLoading}
          disabled={this.state.labelLoading}
          onClick={() => {
            this.createLabel();
          }}
        >
          Label aanmaken
        </LoadingButton>
      );
    }
  };

  downloadInvoice = () => {
    fetch(
      host + "api/admin/orders/" + encodeURIComponent(this.state.id) + "/pdf",
      {
        method: "GET",
        credentials: "include",
        // Pass authentication token as bearer token in header
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.props.token}`,
        },
      }
    )
      .then(async (response) => {
        if (response.ok) {
          const blob = await response.blob();
          var pdfURL = window.URL.createObjectURL(blob);
          var tempLink = document.createElement("a");
          tempLink.href = pdfURL;
          tempLink.target = "_blank";
          // tempLink.setAttribute('download', this.state.data.invoiceNo+'.pdf');
          tempLink.click();
        } else {
          if (response.status === 401) {
            window.location.reload();
          } else {
            toast.error(
              "Er is een fout opgetreden bij het downloaden van de factuur."
            );
          }
        }
      })
      .catch((err) => {
        toast.error(
          "Er is een fout opgetreden bij het downloaden van de factuur."
        );
      });
  };

  noteChange = (ev) => {
    let data = this.state.data;
    data.adminNotes = ev.target.value;
    this.setState({ data: data });
  };

  saveNote = () => {
    apiCall(
      "api/admin/orders/" + encodeURIComponent(this.state.id) + "/note",
      this.props.token,
      "post",
      { note: this.state.data.adminNotes }
    )
      .then(async (data) => {
        // this.setState({data:data, labelLoading:false})
        toast.success("Notitie opgeslagen");
      })
      .catch((err) => {
        // this.setState({loadError:1, loaded:1})
        toast.error(
          "Er is een fout opgetreden bij het opslaan van de notitie."
        );
        console.error(err);
        this.setState({ labelLoading: false });
      });
  };

  render() {
    // if(this.state.loaded==0){
    //   return <Loading/>
    // } else if(this.state.notFound==true){
    //   return <Loading/>
    // }
    if (this.state.code != 200) {
      return <Loader status={this.state.code} page={"bestelling"} />;
    }
    // if(this.state.loadError){
    //   return <LoadError text="Er is een fout opgetreden bij het laden van klant." />
    // }
    const order = this.state.data;

    let totalPurchasePrice = 0;
    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Bestellingen</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="/admin/orders">Bestellingen</Link>
                  </li>
                  <li className="breadcrumb-item active">{this.state.id}</li>
                </ol>
              </div>
            </Col>
          </Row>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <Row>
                    <Col md="6">
                      <h3 className="d-inline align-middle">
                        {order.invoiceNo}
                      </h3>
                      <div className="d-inline align-middle ml-2 no-print">
                        {badges(order.paymentStatus, order.paymentType)}
                        {this.state.data.parcelId != null ? (
                          <span className="ml-1 badge-soft-info badge font-size-14 p-2">
                            Label: {this.state.data.parcelStatus}
                          </span>
                        ) : null}

                        {this.state.data.shippingMethod !== "ship" ? (
                          <span className="ml-1 badge-soft-info badge font-size-14 p-2">
                            Ophalen
                          </span>
                        ) : null}
                      </div>
                    </Col>
                    <Col md="6" className="text-right no-print">
                      {this.labelBtn()}
                      {this.paymentBtn()}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3">
                      <h5>Factuur gegevens</h5>
                      <p>
                        <strong>{order.customer.name}</strong> t.a.v.{" "}
                        {order.invoiceAddress.firstName}{" "}
                        {order.invoiceAddress.lastName}
                        <br />
                        {order.invoiceAddress.street}{" "}
                        {order.invoiceAddress.houseNo}{" "}
                        {order.invoiceAddress.addition} <br />
                        {order.invoiceAddress.postalCode}{" "}
                        {order.invoiceAddress.city}{" "}
                        {order.invoiceAddress.country}
                      </p>
                    </Col>
                    <Col md="3">
                      {order.deliveryAddress ? (
                        <React.Fragment>
                          <h5>Verzend gegevens</h5>
                          <p>
                            <strong>{order.deliveryAddress.company}</strong>{" "}
                            t.a.v. {order.deliveryAddress.firstName}{" "}
                            {order.deliveryAddress.lastName}
                            <br />
                            {order.deliveryAddress.street}{" "}
                            {order.deliveryAddress.houseNo}{" "}
                            {order.deliveryAddress.addition} <br />
                            {order.deliveryAddress.postalCode}{" "}
                            {order.deliveryAddress.city}{" "}
                            {order.deliveryAddress.country}
                          </p>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </Col>

                    <Col md="6">
                      <div className="order-state-wrapper mt-3 no-print">
                        <Nav pills justified className="progress-bar">
                          <NavItem
                            onClick={() => this.setStatus(0)}
                            className={
                              "fa-circle " +
                              (this.state.data.status >= 0 ? "active" : "")
                            }
                          >
                            Nieuw
                          </NavItem>
                          <NavItem
                            onClick={() => this.setStatus(1)}
                            name={"1"}
                            className={
                              "fa-clock " +
                              (this.state.data.status >= 1 ? "active" : "")
                            }
                          >
                            In behandeling
                          </NavItem>
                          <NavItem
                            onClick={() => this.setStatus(2)}
                            name={"2"}
                            className={
                              "fa-box " +
                              (this.state.data.status >= 2 ? "active" : "")
                            }
                          >
                            {order.shippingMethod === "ship"
                              ? "Verzonden"
                              : "Klaar voor ophalen"}
                          </NavItem>
                          <NavItem
                            onClick={() => this.setStatus(3)}
                            name={"3"}
                            className={
                              "fa-archive " +
                              (this.state.data.status >= 3 ? "active" : "")
                            }
                          >
                            Gearchiveerd
                          </NavItem>
                        </Nav>
                      </div>
                    </Col>

                    <Col md="6">
                      <h5>
                        Notities{" "}
                        {order.notes ? (
                          <i className="fas fa-exclamation-circle text-danger"></i>
                        ) : (
                          ""
                        )}
                      </h5>
                      <p>
                        {order.notes ? (
                          <div class="alert alert-danger" role="alert">
                            {order.notes}
                          </div>
                        ) : (
                          <em>Geen notitie toegevoegd</em>
                        )}
                      </p>

                      <textarea
                        className="form-control mb-2 print-input"
                        value={order.adminNotes}
                        onChange={this.noteChange}
                      ></textarea>
                      <Button
                        color="success"
                        className="no-print"
                        onClick={this.saveNote}
                      >
                        Notitie opslaan
                      </Button>
                    </Col>
                    <Col md="6" className="text-right no-print">
                      <Button
                        color="info"
                        className="mr-2"
                        onClick={() => window.print()}
                      >
                        <i className="far fa-file mr-2" />
                        Download Overzicht
                      </Button>
                      <Button color="success" onClick={this.downloadInvoice}>
                        <i className="far fa-file mr-2" />
                        Download Factuur
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h4>Artikelen</h4>
              <Row>
                <Col md="12">
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Aantal</th>
                          <th width="200px">Afbeelding</th>
                          <th>Artikel</th>
                          <th>Stukprijs</th>
                          <th>Totaal</th>
                          <th>Inkoop</th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.lineItems.map((line_item, i) => {
                          if (line_item.variant) {
                            totalPurchasePrice +=
                              line_item.qty * line_item.variant.purchasePrice;
                          }
                          return (
                            <tr key={i}>
                              <td scope="row">{line_item.qty}x</td>
                              <td>
                                <img
                                  width="140px"
                                  src={
                                    prefix +
                                    (line_item.product != null
                                      ? line_item.product.img
                                      : "placeholder.jpeg")
                                  }
                                />
                              </td>
                              <td>
                                {line_item.productName} {line_item.variantName}{" "}
                                {line_item.itemId && `(${line_item.itemId})`}
                              </td>
                              <td>
                                &euro;{(line_item.price / 100).toFixed(2)}
                              </td>
                              <td>
                                &euro;
                                {(
                                  (line_item.qty * line_item.price) /
                                  100
                                ).toFixed(2)}
                              </td>
                              <td>
                                {line_item.variant
                                  ? `€${(
                                      (line_item.qty *
                                        line_item.variant.purchasePrice) /
                                      100
                                    ).toFixed(2)}`
                                  : null}
                                <span
                                  className="d-block"
                                  style={{ fontSize: "12px" }}
                                >
                                  {line_item.variant
                                    ? `(€${(
                                        line_item.variant.purchasePrice / 100
                                      ).toFixed(2)} per stuk)`
                                    : null}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        {order.shippingMethod == "ship" ? (
                          <tr>
                            <th colSpan="3" className=""></th>
                            <td>Verzendkosten</td>
                            <td>
                              &euro;{roundTo(order.shippingPrice / 100, 2)}
                            </td>
                            <td></td>
                          </tr>
                        ) : null}
                        <tr>
                          <th colSpan="3" className="border-0"></th>
                          <th>Subtotaal</th>
                          <th>&euro;{roundTo(order.total / 100, 2)}</th>
                          <td>&euro;{roundTo(totalPurchasePrice / 100, 2)}</td>
                        </tr>
                        <tr>
                          <th colSpan="3" className="border-0"></th>
                          <td>BTW</td>
                          <td>
                            &euro;{roundTo((order.total / 100) * 0.21, 2)}
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <th colSpan="3" className="border-0"></th>
                          <td>Totaal</td>
                          <td>
                            <b>
                              &euro;{roundTo((order.total / 100) * 1.21, 2)}
                            </b>
                          </td>
                          <td>
                            {roundTo(
                              ((order.total - totalPurchasePrice) /
                                order.total) *
                                100,
                              2
                            )}
                            % winst
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function roundTo(value, decimals) {
  return Number(
    Math.round(+value.toFixed(11) + "e" + decimals) + "e-" + decimals
  ).toFixed(2);
}

export default ClientOrders;
