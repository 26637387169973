import React, { Component } from "react";
import { Button, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";
// Custom Scrollbar

import "chartist/dist/scss/chartist.scss";

function badges(status) {
  if(status === "open"){
    return <span className="badge-soft-primary badge font-size-14 p-2">
      Openstaand
    </span>
  } else if(status === "paid") {
    return <span className="badge-soft-success badge font-size-14 p-2">
      Betaald
    </span>
  } else if(status === 0) {
    return <span className="badge-soft-danger badge font-size-14 p-2">
      Nieuw
    </span>
  } else if(status === 1) {
    return <span className="badge-soft-warning badge font-size-14 p-2">
      In behandeling
    </span>
  } else if(status === 2) {
    return <span className="badge-soft-success badge font-size-14 p-2">
      Verzonden
    </span>
  } else if(status === 3){
    return <span className="badge-soft-success badge font-size-14 p-2">
      Verzonden
    </span>
  }
}

class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: this.props.orders
    };
  }

  render() {
    return (
        <Card>
          <CardBody>
            <h4 className="card-title mb-4">Bestellingen</h4>
            <div className="table-responsive">
              <table className="table table-hover table-centered table-nowrap mb-0">
                <thead>
                  <tr>
                    <th scope="col">Order nummer</th>
                    {this.props.admin?<th scope="col">Klant</th>:""}
                    {this.props.admin?<th scope="col">Klantnummer</th>:""}
                    <th scope="col">Besteld door</th>
                    <th scope="col">Datum</th>
                    <th scope="col">Bedrag</th>
                    <th scope="col">Status</th>
                    <th scope="col">Bekijken</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.orders.map((el)=>{
                    var ordered = new Date(el.orderedOn).toISOString().split('T')[0].split("-")
                    return <tr>
                      <th scope="row">{el.invoiceNo}</th>
                      {this.props.admin?<td>{el.customer}</td>:""}
                      {this.props.admin?<td>{el.customerNumber}</td>:""}
                      <td>{el.orderedBy}</td>
                      <td>{ordered[2]+"-"+ordered[1]+"-"+ordered[0]}</td>
                      <td>&euro;{el.total}</td>
                      <td>
                        {badges(el.orderStatus)}
                      </td>
                      <td>
                        <Button size="sm" tag={Link} color="primary" outline to={(this.props.admin?"/admin/orders/":"/orders/")+el.invoiceNo} >Bekijken</Button>
                      </td>
                    </tr>
                  }
                  )}
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>
    );
  }
}

export default Orders;
