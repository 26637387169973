import React, { Component } from "react";
import { Row, Col, Button, Input, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";



import apiCall from "../../helpers/apiCall"
import { Loading, LoadError } from "../../components/Loading"

import Orders from "../../components/Orders";

import "chartist/dist/scss/chartist.scss";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [
      ]
    };
  }

  componentDidMount() {
    // apiCall("me",this.props.token).then(async data => {
    //   this.setState({data:data, loaded:1})
    // }).catch((err)=>{
    //   this.setState({loadError:1, loaded:1})
    //   console.error(err)
    // })
    apiCall("api/admin/dashboard",this.props.token).then(async data => {
      this.setState({...data, loaded:1})
    }).catch((err)=>{
      this.setState({loadError:1, loaded:1})
      console.error(err)
    })
  }

  render() {
    if(!this.state.loaded){
      return <Loading/>
    }
    if(this.state.loadError){
      return <LoadError text="Er is een fout opgetreden bij het laden van het Dashboard." />
    }

    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={12}>
              <div className="page-title-box">
                <h4 className="font-size-18">Dashboard</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item active">
                    Welkom!
                  </li>
                </ol>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={12}>
              <Orders admin={true} orders={this.state.orders} />
            </Col>
          </Row>


        </div>
      </React.Fragment>
    );
  }
}

export default Dashboard;
