import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col, Button } from "reactstrap";
import SimpleBar from "simplebar-react";
function roundTo(value, decimals) {
    return Number(Math.round(+(value).toFixed(11) + 'e' + decimals) + 'e-' + decimals).toFixed(2);
}

class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      data:[]
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu
    }));
  }
  componentDidMount() {
    window.addEventListener('beforeunload', this.beforeunload.bind(this));
  }

  componentDidUpdate() {
  }


  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.beforeunload.bind(this));
  }

  beforeunload(e) {
    if (this.state.data.length != 0) {
      e.preventDefault();
      e.returnValue = true;
    }
  }


  render() {
    var cartData = this.props.data || []
    return (
      <React.Fragment>
        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="dropdown d-inline-block"
          tag="li"
        >
          <DropdownToggle
            className="btn header-item cart-icon waves-effect"
            id="page-header-notifications-dropdown"
            tag="button"
          >
            <i className="mdi text-gray h2 mdi-cart-outline"></i>
            <span className="badge badge-success badge-pill">{cartData.reduce((acc,val)=>{return acc+val.count},0)}</span>
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu cart-dropdown p-0" right>
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h5 className="m-0 font-size-16">Winkelwagen ({cartData.reduce((acc,val)=>{return acc+val.count},0)})</h5>
                </Col>
              </Row>
            </div>

            <SimpleBar style={{ maxHeight: "350px" }}>
              <Row className="mx-2 mb-3">
                {cartData.map((line)=>
                  <React.Fragment>
                  <Col sm="2">
                    {line.count}x
                  </Col>
                  <Col sm="6">
                    {line.name}
                  </Col>
                  <Col sm="2" className="text-right">
                    &euro;{roundTo(line.price/100,2)}
                  </Col>
                  <Col sm="2" className="text-right">
                    &euro;{roundTo(line.count*line.price/100,2)}
                  </Col>
                  </React.Fragment>
                )}
                <Col sm="10" className="text-right mt-2 pt-2 border-top">
                  Subtotaal<br/>
                  BTW<br/>
                  Totaal
                </Col>
                <Col sm="2" className="text-right mt-2 pt-2 border-top">
                  <b>&euro;{roundTo(cartData.reduce((accumulator,value)=>accumulator+value.count*value.price/100,0),2)}</b><br/>
                  <b>&euro;{roundTo(cartData.reduce((accumulator,value)=>accumulator+value.count*value.price/100,0)*0.21,2)}</b><br/>
                  <b>&euro;{roundTo(cartData.reduce((accumulator,value)=>accumulator+value.count*value.price/100,0)*1.21,2)}</b>
                </Col>
              </Row>
            </SimpleBar>
            <div className="p-2 border-top text-center">
              <Button
                className="btn font-size-14 text-center" color="success" outline
                onClick={()=>this.props.checkout(1)}
              >Reken af
              </Button>
            </div>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}
export default Cart;
