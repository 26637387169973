import React, { Component } from "react";
import { Row, Col, Button, Nav, NavItem } from "reactstrap";
import { Link } from "react-router-dom";

import { Loading } from "../../components/Loading";

function badges(status) {
  if (status === "open") {
    return (
      <span className="badge-soft-primary badge font-size-14 p-2">
        Betaling: Openstaand
      </span>
    );
  } else if (status === "paid") {
    return (
      <span className="badge-soft-success badge font-size-14 p-2">
        Betaling: Betaald
      </span>
    );
  } else if (status === 0) {
    return (
      <span className="badge-soft-danger badge font-size-14 p-2">
        Bestelling: Nieuw
      </span>
    );
  } else if (status === 1) {
    return (
      <span className="badge-soft-warning badge font-size-14 p-2">
        Bestelling: In behandeling
      </span>
    );
  } else if (status === 2) {
    return (
      <span className="badge-soft-success badge font-size-14 p-2">
        Bestelling: Verzonden
      </span>
    );
  } else if (status === 3) {
    return (
      <span className="badge-soft-success badge font-size-14 p-2">
        Bestelling: Verzonden
      </span>
    );
  }
}

const host = process.env.REACT_APP_API;
var prefix = host + "images/";
class ClientOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loaded: false,
      id: this.props.match.params.id,
      notFound: false,
      pageNumber: 1,
      numPages: null,
      width: "0%",
    };
  }

  componentDidMount() {
    fetch(host + "api/orders/" + this.state.id, {
      method: "GET",
      credentials: "include",
      // Pass authentication token as bearer token in header
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(async (response) => {
        if (response.ok) {
          var data = await response.json();
          // data.invoiceAddress = JSON.parse(data.invoiceAddress)
          // data.deliveryAddress = data.deliveryAddress?JSON.parse(data.deliveryAddress):null
          this.setState({ data: data, loaded: true });
        } else {
          if (response.status === 401) {
            // Edge case: when the token has expired.
            // This could happen if the refreshToken calls have failed due to network error or
            // User has had the tab open from previous day and tries to click on the Fetch button
            window.location.reload();
          } else {
          }
        }
      })
      .catch((err) => {
        //handle error
      });
  }

  downloadInvoice = () => {
    fetch(host + "api/orders/" + encodeURIComponent(this.state.id) + "/pdf", {
      method: "GET",
      credentials: "include",
      // Pass authentication token as bearer token in header
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.props.token}`,
      },
    })
      .then(async (response) => {
        if (response.ok) {
          const blob = await response.blob();
          var pdfURL = window.URL.createObjectURL(blob);
          var tempLink = document.createElement("a");
          tempLink.href = pdfURL;
          tempLink.setAttribute("download", this.state.data.invoiceNo + ".pdf");
          tempLink.click();
        } else {
          if (response.status === 401) {
            window.location.reload();
          } else {
          }
        }
      })
      .catch((err) => {});
  };
  onDocumentLoadSuccess = (data) => {
    this.setState({ numPages: data._pdfInfo.numPages });
  };
  render() {
    if (this.state.loaded === false) {
      return <Loading />;
    } else if (this.state.notFound === true) {
      return <Loading />;
    }
    const order = this.state.data;
    return (
      <React.Fragment>
        <div className="container-fluid">
          <Row className="align-items-center">
            <Col sm={6}>
              <div className="page-title-box">
                <h4 className="font-size-18">Bestellingen</h4>
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="/orders">Bestellingen</Link>
                  </li>
                  <li className="breadcrumb-item active">{this.state.id}</li>
                </ol>
              </div>
            </Col>
          </Row>

          <div className="row">
            <div className="col-12">
              {/*{this.state.invoice?
              <div className="card">
                <div className="card-body">
              <Sample width="50%" data={this.state.invoice}/>
              </div>
              </div>
              :""}}<Document
              renderMode="svg"
                file={this.state.invoice}
                onLoadSuccess={this.onDocumentLoadSuccess}
              >
                <Page pageNumber={this.state.pageNumber} />
              </Document>*/}
              <div className="card">
                <div className="card-body">
                  <Row>
                    <Col md="9">
                      <h3 className="d-inline align-middle">
                        {order.invoiceNo}
                      </h3>
                      <div className="d-inline align-middle ml-2">
                        {badges(order.paymentStatus)}
                      </div>
                      {/*}<div className="d-inline align-middle ml-2">
                        {badges(order.status)}
                      </div>*/}
                    </Col>
                    <Col md="3" className="text-right">
                      <Button color="primary" onClick={this.downloadInvoice}>
                        <i className="far fa-file mr-2" />
                        Download Factuur
                      </Button>
                    </Col>
                  </Row>

                  <Row>
                    <Col md="3">
                      <h5>Factuur gegevens</h5>
                      <p>
                        <strong>{order.customer.name}</strong> t.a.v.{" "}
                        {order.invoiceAddress.firstName}{" "}
                        {order.invoiceAddress.lastName}
                        <br />
                        {order.invoiceAddress.street}{" "}
                        {order.invoiceAddress.houseNo}{" "}
                        {order.invoiceAddress.addition} <br />
                        {order.invoiceAddress.postalCode}{" "}
                        {order.invoiceAddress.city}
                      </p>
                    </Col>

                    <Col md="3">
                      {order.deliveryAddress ? (
                        <React.Fragment>
                          <h5>Verzend gegevens</h5>
                          <p>
                            {order.deliveryAddress.firstName}{" "}
                            {order.deliveryAddress.lastName}
                            <br />
                            {order.deliveryAddress.street}{" "}
                            {order.deliveryAddress.houseNo}{" "}
                            {order.deliveryAddress.addition} <br />
                            {order.deliveryAddress.postalCode}{" "}
                            {order.deliveryAddress.city}
                          </p>
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </Col>

                    <Col md="6">
                      <div className="order-state-wrapper customer mt-3">
                        <Nav pills justified className="progress-bar">
                          <NavItem
                            className={
                              "fa-circle " +
                              (this.state.data.status >= 0 ? "active" : "")
                            }
                          >
                            Nieuw
                          </NavItem>
                          <NavItem
                            name={"1"}
                            className={
                              "fa-clock " +
                              (this.state.data.status >= 1 ? "active" : "")
                            }
                          >
                            In behandeling
                          </NavItem>
                          <NavItem
                            name={"2"}
                            className={
                              "fa-box " +
                              (this.state.data.status >= 2 ? "active" : "")
                            }
                          >
                            {order.shippingMethod === "ship"
                              ? "Verzonden"
                              : this.state.data.status == 2
                              ? "Klaar voor ophalen"
                              : "Opgehaald"}
                          </NavItem>
                        </Nav>
                      </div>
                    </Col>
                    <Col md="12">
                      <h5>Notities</h5>
                      <p>{order.notes || <em>Geen notitie toegevoegd</em>}</p>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h4>Artikelen</h4>
              <Row>
                <Col md="12">
                  <div className="table-responsive">
                    <table className="table mb-0">
                      <thead>
                        <tr>
                          <th>Aantal</th>
                          <th width="200px">Afbeelding</th>
                          <th>Artikel</th>
                          <th>Stukprijs</th>
                          <th>Totaal</th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.lineItems.map((line_item, i) => (
                          <tr key={i}>
                            <td scope="row">{line_item.qty}x</td>
                            <td>
                              <img
                                width="140px"
                                src={
                                  prefix +
                                  (line_item.product != null
                                    ? line_item.product.img
                                    : "placeholder.jpeg")
                                }
                              />
                            </td>
                            <td>
                              {line_item.productName} {line_item.variantName}
                            </td>
                            <td>&euro;{(line_item.price / 100).toFixed(2)}</td>
                            <td>
                              &euro;
                              {(
                                (line_item.qty * line_item.price) /
                                100
                              ).toFixed(2)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        {order.shippingMethod == "ship" ? (
                          <tr>
                            <th colSpan="3" className=""></th>
                            <td>Verzendkosten</td>
                            <td>
                              &euro;{roundTo(order.shippingPrice / 100, 2)}
                            </td>
                          </tr>
                        ) : null}
                        <tr>
                          <th colSpan="3" className="border-0"></th>
                          <th>Subtotaal</th>
                          <th>&euro;{roundTo(order.total / 100, 2)}</th>
                        </tr>
                        <tr>
                          <th colSpan="3" className="border-0"></th>
                          <td>BTW</td>
                          <td>
                            &euro;{roundTo((order.total / 100) * 0.21, 2)}
                          </td>
                        </tr>
                        <tr>
                          <th colSpan="3" className="border-0"></th>
                          <td>Totaal</td>
                          <td>
                            <b>
                              &euro;{roundTo((order.total / 100) * 1.21, 2)}
                            </b>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function roundTo(value, decimals) {
  return Number(
    Math.round(+value.toFixed(11) + "e" + decimals) + "e-" + decimals
  ).toFixed(2);
}

export default ClientOrders;
