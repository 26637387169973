import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, REFRESH_TOKEN } from './actionTypes';
import { loginSuccess, logoutUserSuccess, apiError, refreshTokenSuccess } from './actions';

//AUTH related methods
import { getBackend } from '../../../helpers/authUtils';

const Backend = getBackend();

function* loginUser({ payload: { user, history } }) {
    try {
        const response = yield call(Backend.loginUser, user.email, user.password);
        yield put(loginSuccess(response));
        history.push('/');
    } catch (error) {
        yield put(apiError(error));
    }
}

function* logoutUser({ payload: { history } }) {
    try {
        const response = yield call(Backend.logout);
        yield put(logoutUserSuccess(response));
        history.push('/login');
    } catch (error) {
        yield put(apiError(error));
    }
}

function* refreshToken({ payload: { history } }) {
    try {
        const response = yield call(Backend.refreshToken);
        yield put(refreshTokenSuccess(response));
        // history.push('/login');
    } catch (error) {
        yield put(apiError(error));
    }
}

export function* watchUserLogin() {
    yield takeEvery(LOGIN_USER, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

export function* watchRefreshToken() {
    yield takeEvery(REFRESH_TOKEN, refreshToken)
}

function* authSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
        fork(watchRefreshToken),
    ]);
}

export default authSaga;
