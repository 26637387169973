import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {Card, CardBody,CardText, Row, Col, Input, Label, FormGroup, Button, Modal, InputGroup, InputGroupAddon, InputGroupText, UncontrolledDropdown,
DropdownMenu,
DropdownItem,
DropdownToggle,
UncontrolledTooltip} from 'reactstrap'

import Image from "./Image";

import { toast } from 'react-toastify';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import apiCall from "../../helpers/apiCall"
// fake data generator
const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item ${k}`,
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex, sourceCategory, destCategory) => {
  // const result = Array.from(list);
  // if(startIndex > endIndex){
  //   for(var i=endIndex; i<startIndex; i++){
  //     console.log("startIndex > endIndex",i)
  //     result[i].order--
  //   }
  // } else if(startIndex < endIndex) {
  //   for(var i=endIndex; i>startIndex; i--){
  //     console.log("startIndex < endIndex",i,result[i].order,result[i].order-1)
  //     result[i].order--
  //   }
  // }
  // result[startIndex].order = endIndex
  // for var i=endIndex;i<result.
  //result[category][startIndex].order = endIndex
  // const [removed] = result.splice(startIndex, 1);
  // result.splice(endIndex, 0, removed);
  // result.sort()
  // return result;
  const result = Array.from(list);
  const [removed] = result[sourceCategory].products.splice(startIndex, 1);
  result[destCategory].products.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: grid,
  // boxShadow:  isDragging ? '4px 4px 20px 0px' : '4px 4px 18px -5px',
  // borderRadius: "0.25rem",
  // transition: "box-shadow 1s",

  ...draggableStyle,
});

const getCardStyle = (isDragging) => ({
  // boxShadow:  isDragging ? '4px 4px 20px 0px' : '4px 4px 18px -5px',
  border: "1px solid #ced4da",
  transition: "box-shadow 0.25s",
  width: "300px", marginBottom:0
});

const getListStyle = isDraggingOver => ({
  //background: isDraggingOver ? 'lightblue' : 'lightgrey',
  display: 'flex',
  padding: grid*0.5,
  borderRadius: "0.25rem",
  border: "1px solid #ced4da",
  minHeight: "50px",
  marginBottom:"1em",
  overflow: 'auto',
});

const getAllStyle = isDraggingOver => ({
  //background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid*0.5,
  minHeight: "50px",
  marginBottom:"1em",
});

const getCategoryStyle = isDraggingOver => ({
  //background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid*0.5,
  minHeight: "50px",
  display: 'flex',
  width:"100%",
  marginBottom:"1em",
});
const host = process.env.REACT_APP_API
var prefix = host+"images/"
class ProductModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      data: this.props.data
    }

  }

  componentDidMount(){
    this.setState({open:true,data:this.props.data})
  }

  updateData = (key,event) => {
    var data = this.state.data
    var field = event.target.name
    if(field==="price"){
      var value = event.target.value
      if(!isNaN(value)){
        data.variants[key][field] = event.target.value
      }
    } else {
      data.variants[key][field] = event.target.value
    }
    //console.log(event.target.value,index,key,data[index].variants[key])
    this.props.callback(data)
  }

  updatePrice = (event) => {
    var data = this.state.data
    var field = event.target.name
    if(!isNaN(event.target.value)){
      for(var i in data.variants) {
        data.variants[i][field] = event.target.value
      }
    }
    //console.log(event.target.value,index,key,data[index].variants[key])
    this.props.callback(data)
  }

  reorder = (key, dir) => {
    var data = this.state.data
    data.variants.splice(key+(dir=="up"?-1:1), 0, data.variants.splice(key, 1)[0]);
    //console.log(event.target.value,index,key,data[index].variants[key])
    this.props.callback(data)
  }

  updateName = (ev) => {
    var data = this.state.data
    data.name = ev.target.value

    this.props.callback(data)
  }

  closeModal = () => {
    this.setState({open:false})
    setTimeout(()=>{this.props.open(false)},500)
  }

  addVariant = () => {
    var data = this.state.data
    data.variants.push({
      id:"new-"+uuidv4(),
      name:"Nieuwe variant",
      price: this.state.data.variants[0]?.price || "",
      itemId:""
    })
    this.props.callback(data)
  }

  removeVariant = (i) => {
    var data = this.state.data
    // console.log(categories,idx)
    data.variants.splice(i,1)
    this.props.callback(data)
  }

  render() {
    const data = this.state.data
    return (
      <Modal
      centered={true}
      size="lg"
        isOpen={this.state.open}
        toggle={this.closeModal}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="Modal">
            {this.state.data.name}
          </h5>
          <button
            type="button"
            onClick={this.closeModal}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div>
          <img ref={img => this.img = img} onError={()=>{this.img.src=prefix+"placeholder.jpeg"}} src={data.img} className="w-100 card-img-top img-fluid"/>
        </div>
        <div className="modal-body">
          <Row className="mb-2">
            <Col xs={4}>
              <Label>Product naam</Label>
              <Input value={this.state.data.name} onChange={this.updateName}/>
            </Col>
            <Col xs={4}>
              <Label>Inkoop prijs</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>&euro;</InputGroupText>
                </InputGroupAddon>
                <Input name={"purchasePrice"} type="text" className="form-control" value={this.state.data.variants[0]?.purchasePrice || ""} onChange={this.updatePrice}/>
              </InputGroup>
            </Col>
            <Col xs={4}>
            <Label>Verkoop prijs</Label>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>&euro;</InputGroupText>
                </InputGroupAddon>
                <Input name={"price"} type="text" className="form-control" value={this.state.data.variants[0]?.price || ""} onChange={this.updatePrice}/>
              </InputGroup>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col sm={3}>
              <Label>Maat</Label>
            </Col>
            {/*<Col sm={3}>
              <Label>Prijs</Label>
            </Col>*/} 
            <Col sm={6}>
              <Label>art. nr</Label>
            </Col>
          </Row>

          {data.variants.map((size,i)=>
            <Row key={size.id} className="mb-2">
              <Col xs={3}>
                <Input id={"size"+i} name={"name"} type="text" className="form-control" onChange={(ev)=>this.updateData(i,ev)} value={size.name}/>
              </Col>
              {/*<Col xs={3}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>&euro;</InputGroupText>
                  </InputGroupAddon>
                  <Input name={"price"} type="text" className="form-control" onChange={(ev)=>this.updateData(i,ev)} value={size.price}/>
                </InputGroup>
              </Col>*/}
              <Col xs={"auto"} className="flex-grow-1">
                <Input name={"itemId"} type="text" className="form-control" onChange={(ev)=>this.updateData(i,ev)} value={size.itemId}/>
              </Col>
              <Col xs={"auto"}>
                {/*<Button color="light" className="mr-2" disabled={i==0} onClick={()=>{this.reorder(i,"up")}}><i className="fas fa-arrow-up"/></Button>
                <Button color="light" className="mr-2" disabled={i==data.variants.length-1}  onClick={()=>{this.reorder(i,"down")}}><i className="fas fa-arrow-down"/></Button>*/}
                <Button color="danger" onClick={()=>{this.removeVariant(i)}}><i className="fas fa-minus"/></Button>
              </Col>
            </Row>
          )}
          <Button onClick={this.addVariant}>Variant toevoegen</Button>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
            onClick={this.closeModal}
          >
            Sluiten
          </button>
        </div>
      </Modal>
    )
  }
}
const { v4: uuidv4 } = require('uuid');
window.uuid = uuidv4
// uuidv4(); // ⇨ '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed'
function reshape(data){
  var categories = Array.from(new Set([...data.map((el)=>{return el.category})]))
  var reshaped = categories.map((name)=>{
    return {
      id:uuidv4(),
      name: name,
      items: data.filter((item)=>item.category===name).map((item)=>{
        return {
          id: uuidv4(),
          ...item
        }
      })
    }
  })
  return reshaped
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editing:0,
      categories:null,
      openModal:false,
      category:null,
      product:null,
      open:false,
      saving:false
    };
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    //var data = reshape(this.props.data)
    // this.setState({categories: [...this.props.data]})
    // this.setState({categories: })
    var data = JSON.parse(JSON.stringify(this.props.data))
    data.forEach((item, i) => {
      item.products.forEach((item, i) => {
        item.img = prefix+item.img
        item.variants.forEach((item, i) => {
          item.price = item.price/100
          item.purchasePrice = item.purchasePrice/100
        });
      });
    });
    this.setState({categories: data})
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const { source, destination ,type} = result;
    if (type==="column") {
      const startIndex = result.source.index;
      const endIndex = result.destination.index;
      const data = Array.from(this.state.categories);
      const [removed] = data.splice(startIndex, 1);
      data.splice(endIndex, 0, removed);
      this.setState({
        categories:data,
      });

    } else {
      const data = reorder(this.state.categories, result.source.index, result.destination.index,this.state.categories.findIndex((el)=>el.id.toString()===source.droppableId), this.state.categories.findIndex((el)=>el.id.toString()===destination.droppableId))
      this.setState({
        categories:data,
      });
    }
  }
  updateCatName = (ev, cat) => {
    var val = ev.target.value
    var categories = this.state.categories
    categories[cat].name = val
    this.setState({
      categories
    })
  }

  cancel = () => {
    var data = JSON.parse(JSON.stringify(this.props.data))
    data.forEach((item, i) => {
      item.products.forEach((item, i) => {
        item.img = prefix+item.img
        item.variants.forEach((item, i) => {
          item.price = item.price/100
          item.purchasePrice = item.purchasePrice/100
        });
      });
    });

    this.setState({categories: data, editing:0})
    //this.setState({categories: JSON.parse(JSON.stringify(this.props.data)), editing:0})
  }
  save = () => {
    const formData  = new FormData();

    var data = [...this.state.categories]
    data.forEach((item, i) => {
      item.products.forEach((item, i) => {
        if(item.img.startsWith("blob")){
          formData.append(item.img, item.blob);
        } else {
          item.img = item.img.replace(prefix,"")
        }
        item.variants.forEach((item, i) => {
          item.price = parseInt(item.price*100)
          item.purchasePrice = parseInt(item.purchasePrice*100)
        });

      });
    });
    formData.append("data", JSON.stringify(data));
    this.setState({saving:true});
    apiCall("api/admin/customers/"+encodeURIComponent(this.props.id)+"/products",this.props.token,"multipart", formData).then(async data => {
      this.props.savedData(JSON.parse(JSON.stringify(data)))
      data.forEach((item, i) => {
        item.products.forEach((item, i) => {
          item.img = prefix+item.img
          item.variants.forEach((item, i) => {
            item.price = item.price/100
            item.purchasePrice = item.purchasePrice/100
          });
        });
      });

      this.setState({categories:data, editing:0, saving:false})
      toast.success("Producten opgeslagen.")
    }).catch((err)=>{
      // this.setState({loadError:1, loaded:1})
      toast.error("Er is een fout opgetreden bij het opslaan van producten.")
      console.error(err)
    })
  }

  productModalCallback = (data) => {
    var categories = this.state.categories
    categories[this.state.category].products[this.state.product] = data
    this.setState({
       categories:categories
    })
  }

  addProduct = (cat) => {
    var categories = this.state.categories
    var idx = categories.findIndex((el)=>el.id===cat)
    categories[idx].products.push({
      id:"new-"+uuidv4(),
      name:"Nieuw Product",
      img:prefix+"placeholder.jpeg",
      variants: [{
        id:"new-"+uuidv4(),
        name:"S",
        price:0,
        itemId:""
      },
      {
        id:"new-"+uuidv4(),
        name:"M",
        price:0,
        itemId:""
      },{
        id:"new-"+uuidv4(),
        name:"L",
        price:0,
        itemId:""
      },
      {
        id:"new-"+uuidv4(),
        name:"XL",
        price:0,
        itemId:""
      },
      {
        id:"new-"+uuidv4(),
        name:"XXL",
        price:0,
        itemId:""
      }]
    })
    this.setState({
      categories
    })
  }

  addCategory = (cat) => {
    var categories = this.state.categories
    var idx = categories.findIndex((el)=>el.id===cat)
    categories.splice(idx+1,0,...[{id: "new-"+uuidv4(),
      name: "Nieuw Categorie",
      products: []
    }])
    this.setState({
      categories
    })
  }

  removeCategory = (cat) => {
    var categories = this.state.categories
    var idx = categories.findIndex((el)=>el.id===cat)
    categories.splice(idx,1)
    this.setState({
      categories
    })
  }

  removeProduct = (cat,pos) => {
    var categories = this.state.categories
    var idx = categories.findIndex((el)=>el.id===cat)
    categories[idx].products.splice(pos,1)
    this.setState({
      categories
    })
  }

  toggle = (toggle) => {
    this.setState({open:toggle})
  }
  croppedImage = (img, blob) => {
    var categories = this.state.categories
    categories[this.state.category].products[this.state.product].img = img
    categories[this.state.category].products[this.state.product].blob = blob
    this.setState({categories:categories})
  }
  prefixImage = (img) =>{
    if(!img.startsWith("blob:")){
      return prefix+img//.lastIndexOf("/")
    }
  }

  render() {
    if(this.state.categories===null) {
      return ""
    }
    return (
      <React.Fragment>
      <Image open={this.state.open} toggle={this.toggle} croppedImage ={this.croppedImage}/>
        <Row>
          <Col xl="6"><h3>Producten</h3></Col>
          <Col xl="6" className="text-right">
          {this.state.editing?
            <React.Fragment>
              <Button key="cancel" className="mr-2" onClick={this.cancel} color="light"><i className="mdi mdi-check d-inline mr-2"></i>Annuleer</Button>
              <Button key="save" onClick={this.save} color="success"><i className={this.state.saving?"mdi mdi-loading mdi-spin d-inline mr-2":"mdi mdi-check d-inline mr-2"}></i>Opslaan</Button>
            </React.Fragment>:
            <React.Fragment>
              <Button key="edit" onClick={()=>this.setState({editing:!this.state.editing})} color="primary"><i className="mdi mdi-pencil d-inline mr-2"></i>Aanpassen</Button>
            </React.Fragment>}
          </Col>
        </Row>
        {this.state.categories.length===0?<div className="text-center"><Button disabled={!this.state.editing} color="warning" outline onClick={()=>{this.addCategory(-1)}}>Categorie toevoegen</Button></div>:""}
        <DragDropContext onDragEnd={(result)=>this.onDragEnd(result)}>
          <Droppable droppableId="column" direction="vertical" style={{display:"block"}} type="column">
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef} style={{ width: "100%"}} >
                 <div className="shadowTransition mb-4" style={{ boxShadow: snapshot.isDragging ? "rgb(0 0 0 / 20%) 10px 10px 15px 5px" : "none", borderRadius: 5 }} >
                  {this.state.categories.map((cat, catId) => {
                    const products = cat.products
                    return (
                      <Draggable key={cat.id} isDragDisabled={!this.state.editing}  draggableId={"category-"+cat.id} index={catId}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{borderRadius:"0.25em", background:"#fff", userSelect: "none",margin:"0.5rem",...provided.draggableProps.style}}>
                          <Row>
                            <Col sm="3" style={{marginBottom:"0.5em"}}>

                              <Input type="text" name="category" disabled={!this.state.editing} onChange={(ev)=>this.updateCatName(ev, catId)} value={cat.name}/>

                            </Col>
                            <Col sm="9" style={{marginBottom:"0.5em"}}>
                              <div className="d-flex justify-content-between">
                              <div className="align-self-center">
                                {products.length===0 || products.reduce((acc,x)=>{return [...acc,...x.variants]},[]).length===0?
                                  <React.Fragment>
                                    <i className="fas fa-info-circle text-warning" id={"tooltip-"+cat.id}></i>
                                    <UncontrolledTooltip target={"tooltip-"+cat.id}>Deze categorie zal niet zichtbaar zijn voor de klant omdat het geen producten met varianten bevat</UncontrolledTooltip>
                                  </React.Fragment>:""}
                              </div>

                              <UncontrolledDropdown>
                                <DropdownToggle disabled={!this.state.editing} className="btn btn-info"  caret>
                                  <i className="fas fa-cog d-inline text-white"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem onClick={()=>{this.addProduct(cat.id)}}><i className="fas fa-plus d-inline mr-2"></i>Product toevoegen</DropdownItem>
                                  <DropdownItem onClick={()=>{this.addCategory(cat.id)}}><i className="fas fa-plus d-inline mr-2"></i>Categorie onder toevoegen</DropdownItem>
                                  <DropdownItem className="text-danger" onClick={()=>{this.removeCategory(cat.id)}}><i className="fas fa-minus d-inline mr-2"></i>Categorie verwijderen</DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                              </div>
                            </Col> 

                          </Row>
                          <Droppable droppableId={cat.id.toString()} direction="horizontal">
                            {(provided, snapshot) => (
                              <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps}>
                              {products.map((product, prodId) => (
                                <Draggable key={product.id} isDragDisabled={!this.state.editing}  draggableId={product.id.toString()} index={prodId}>
                                {(provided, snapshot) => (
                                  <div className="d-flex align-items-stretch" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging,provided.draggableProps.style)}>
                                  <Card style={getCardStyle(snapshot.isDragging)}>
                                    <div className="p-2 position-relative mb-0">
                                      <img ref={img => this[product.id] = img} onError={()=>{this[product.id].src=prefix+"placeholder.jpeg"}} src={product.img} className="w-100 card-img-top img-fluid rounded-top"/>
                                      <div class={"overlay "+(this.state.editing?"":"d-none")}>
                                        <div>
                                          <Button color="primary" onClick={()=>{if(this.state.editing){this.setState({open:true,category:catId,product:prodId})}}}>Afbeelding uploaden</Button>
                                        </div>
                                      </div>
                                    </div>
                                    <CardBody className="d-flex flex-column">
                                      <div className="d-block">
                                        <h4 className="mb-0 d-inline">{product.name}</h4>
                                        {product.variants.length===0?
                                          <React.Fragment>
                                            <i className=" ml-2 fas d-inline fa-info-circle text-warning" id={"tooltip-"+product.id}></i>
                                            <UncontrolledTooltip target={"tooltip-"+product.id}>Dit product zal niet zichtbaar zijn voor de klant omdat het geen varianten bevat</UncontrolledTooltip>
                                          </React.Fragment>:""}
                                      </div>
                                      <p style={{fontSize:"11px"}} className="mb-1 flex-grow-1">{product.variants.map((el)=>el.name).join("-")}</p>
                                      <div className="d-flex justify-content-between">
                                      <Button disabled={!this.state.editing} color="success" onClick={()=>{this.setState({openModal:true,category:catId,product:prodId})}} outline>Instellingen</Button>
                                      <div className="d-block align-self-end">
                                        <Button disabled={!this.state.editing} size="sm" color="danger" onClick={()=>{this.removeProduct(cat.id, prodId)}} outline>Verwijder</Button>
                                      </div>
                                      </div>
                                    </CardBody>
                                  </Card>
                                </div>
                              )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                            </div>
                          )}
                          </Droppable>
                        </div>
                      )}
                      </Draggable>
                    )
                  })
                }
                {provided.placeholder}
              </div>
            </div>
            )}
          </Droppable>
        </DragDropContext>
        {this.state.openModal==true?<ProductModal open={(state)=>{this.setState({openModal:state})}} data={this.state.categories[this.state.category].products[this.state.product]} callback={this.productModalCallback}/>:""}
      </React.Fragment>
    );
  }
}

export default App
